<body>
    <div class="container-scroller">
      <!-- partial:partials/_navbar.html -->
      <nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
          <a class="navbar-brand brand-logo" href="#">
            <img src="assets/images/logo.png" height="80">
          </a>
        </div>
        <div class="navbar-menu-wrapper d-flex align-items-center">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item dropdown d-none d-xl-inline-block user-dropdown">
              <a class="nav-link dropdown-toggle" id="UserDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                <img class="img-xs rounded-circle" src="../assets/images/faces/face8.jpg" alt="Profile image"> </a>
              <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
                <div class="dropdown-header text-center">
                  <img class="img-md rounded-circle" src="../assets/images/faces/face8.jpg" alt="Profile image">
                  <p class="mb-1 mt-3 font-weight-semibold"></p>
                  <p class="font-weight-light text-muted mb-0">Admin</p>
                </div>
                <a class="dropdown-item" routerLink="/admin/profile">My Profile <i class="dropdown-item-icon ti-dashboard"></i></a>
                <a class="dropdown-item" style="cursor: pointer;" (click)="logout()">Sign Out<i class="dropdown-item-icon ti-power-off"></i></a>
              </div>
            </li>
          </ul>
          <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
            <span class="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
      <!-- partial -->
      <div class="container-fluid page-body-wrapper">
        <!-- partial:partials/_sidebar.html -->
        <nav class="sidebar sidebar-offcanvas" id="sidebar">
          <ul class="nav">
            <li class="nav-item nav-profile">
              <a href="#" class="nav-link">
                <div class="profile-image">
                  <img class="img-xs rounded-circle" src="../assets/images/faces/face8.jpg" alt="profile image">
                  <div class="dot-indicator bg-success"></div>
                </div>
                <div class="text-wrapper">
                  <p class="profile-name"></p>
                  <p class="designation">Admin</p>
                </div>
              </a>
            </li>
            <li class="nav-item nav-category">Main Menu</li>
            <li class="nav-item">
              <a class="nav-link active" routerLink="/admin/dashboard">
                <i class="menu-icon typcn typcn-document-text"></i>
                <span class="menu-title">Dashboard</span>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" routerLink="/admin/add-agent">
                <i class="menu-icon typcn typcn-user-add"></i>
                <span class="menu-title">Add Agent</span>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" routerLink="/admin/view-agent">
                <i class="menu-icon typcn typcn-eye-outline"></i>
                <span class="menu-title">View Agents</span>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" routerLink="/admin/add-exco">
                <i class="menu-icon typcn typcn-user-outline"></i>
                <span class="menu-title">Add Exco</span>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" routerLink="/admin/excos">
                <i class="menu-icon typcn typcn-eye-outline"></i>
                <span class="menu-title">View Excos</span>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" routerLink="/admin/verify">
                <i class="menu-icon typcn typcn-tick"></i>
                <span class="menu-title">Verify Account</span>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" routerLink="/admin/revalidation/user/update">
                <i class="menu-icon typcn typcn-tick"></i>
                <span class="menu-title">Re-validation</span>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" routerLink="/admin/export">
                <i class="menu-icon typcn typcn-business-card"></i>
                <span class="menu-title">Export Card</span>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" routerLink="/admin/profile">
                <i class="menu-icon typcn typcn-user"></i>
                <span class="menu-title">Profile</span>
              </a>
            </li>
          </ul>
        </nav>
        <!-- partial -->
        <div class="main-panel">
          <div class="content-wrapper">
            <!-- Page Title Header Starts-->
            <div class="row page-title-header">
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  
                  <div class="col-md-3 grid-margin stretch-card average-price-card">
                    <div class="card text-white">
                      <div class="card-body">
                        <div class="d-flex justify-content-between pb-2 align-items-center">
                          <h2 class="font-weight-semibold mb-0">{{ totalUsers }}</h2>
                          <div class="icon-holder">
                            <i class="mdi mdi-briefcase-outline"></i>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between">
                          <h5 class="font-weight-semibold mb-0">Cards</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 grid-margin stretch-card average-price-card">
                    <div class="card text-white">
                      <div class="card-body">
                        <div class="d-flex justify-content-between pb-2 align-items-center">
                          <h2 class="font-weight-semibold mb-0">{{ totalAgents }}</h2>
                          <div class="icon-holder">
                            <i class="mdi mdi-briefcase-outline"></i>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between">
                          <h5 class="font-weight-semibold mb-0">Agents</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
                    <p style="color: white">Please Wait. </p>
                  </ngx-spinner>
                  <div class="modal fade" id="{{ user.fingerId }}" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" *ngFor = "let user of users">
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">ID Card</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <app-card [data]="user"></app-card>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 grid-margin">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex justify-content-between">
                          <h4 class="card-title mb-0">Users</h4>
                        </div>
                        <p>Registered Users</p>

                        <div class="row">
                          <button class="btn btn-secondary" (click)="exporter.exportTable('csv')">Csv</button> &nbsp;
                          <button class="btn btn-secondary" (click)="exporter.exportTable('json')">Json</button> &nbsp;
                          <button class="btn btn-secondary" (click)="exporter.exportTable('txt')">Txt</button>
                        </div>

                        <div class="example-header">
                          <mat-form-field>
                            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                          </mat-form-field>
                          <div class="col-md-6 col-sm-12 form-group">
                            <label for="inputState">Select Filter</label>
                            <select class="form-control search-form" [(ngModel)]="selectedFilter" (change)="activateButton()">
                                <option *ngFor="let item of filters">{{item}}</option>
                            </select>
                          </div>
                        </div>

                        <!--Check here-->

                        <form [formGroup]="credentialsForm" class="p-2">
                    
                          <!-- if selectedFilter is Branch-->
                          <div class="form-row" *ngIf="selectedFilter == 'Branch'">
                              <div class="form-group col-md-6">
                                  <label for="inputState">Branch</label>
                                  <select formControlName="branch" class="form-control dash-form-control"
                                  [ngClass]="{ 'is-invalid': credentialsForm.controls.branch.invalid && (credentialsForm.controls.branch.dirty
                                  || credentialsForm.controls.branch.touched)}">
                                    <option></option>
                                      <option *ngFor="let branch of branches">{{ branch }}</option>
                                  </select>
                              </div>
                              
                          </div>
                          <!-- if selectedFilter is Date-->
                          <div class="form-row" *ngIf="selectedFilter == 'Date'">
                              <div class="form-group col-md-6">
                                  <mat-form-field>
                                      <mat-label>Start date</mat-label>
                                      <input matInput class="form-control" [matDatepicker]="picker"
                                      formControlName="startDate">
                                      <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
                                      <mat-datepicker #picker></mat-datepicker>
                                  </mat-form-field>
                              </div>
                              <div class="form-group col-md-6">
                                  <mat-form-field>
                                      <mat-label>End date</mat-label>
                                      <input matInput class="form-control" [matDatepicker]="picker1"
                                      formControlName="endDate">
                                      <mat-datepicker-toggle matSuffix [for]="picker1" ></mat-datepicker-toggle>
                                      <mat-datepicker #picker1></mat-datepicker>
                                  </mat-form-field>
                              </div>
                          </div>

                          <div class="mt-3" *ngIf="showButton == true">
                              <button class="btn btn-dash mt-3" (click)="getAllUsersWithFilter()">Apply</button>
                          </div>
                      </form>

                        <div class="example-container mat-elevation-z8 table-responsive">

                          <table mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort matSortStart="desc">

                            <!-- Name Column -->
                            <ng-container matColumnDef="firstname">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                              <td mat-cell *matCellDef="let row"> {{row.firstname}} {{row.middlename}} {{row.surname}} </td>
                            </ng-container>

                            <!-- UniqueID Column -->
                            <ng-container matColumnDef="uniqueId">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Unique ID </th>
                              <td mat-cell *matCellDef="let row"> {{row.uniqueId | uppercase}} </td>
                            </ng-container>

                            <!-- Progress Column -->
                            <ng-container matColumnDef="email">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                              <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                            </ng-container>

                            <!-- Color Column -->
                            <ng-container matColumnDef="Phone No">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone No </th>
                              <td mat-cell *matCellDef="let row"> {{row.phone_no}} </td>
                            </ng-container>

                            <!-- Color Column -->
                            <ng-container matColumnDef="gender">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender </th>
                              <td mat-cell *matCellDef="let row"> {{row.gender}} </td>
                            </ng-container>

                            <!-- Color Column -->
                            <ng-container matColumnDef="dob">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> D.O.B </th>
                              <td mat-cell *matCellDef="let row"> {{row.dob | date}} </td>
                            </ng-container>

                            <!-- Color Column -->
                            <ng-container matColumnDef="branch">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Branch </th>
                              <td mat-cell *matCellDef="let row"> {{row.branch}} </td>
                            </ng-container>

                            <!-- Color Column -->
                            <ng-container matColumnDef="zone">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Zone 
                              </th>
                              <td mat-cell *matCellDef="let row"> {{row.zone}} </td>
                            </ng-container>

                            <!-- Color Column -->
                            <ng-container matColumnDef="state">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> State </th>
                              <td mat-cell *matCellDef="let row"> {{row.state}} </td>
                            </ng-container>

                            <!-- Color Column -->
                            <ng-container matColumnDef="unit">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit LGA
                              </th>
                              <td mat-cell *matCellDef="let row"> {{row.unit}} </td>
                            </ng-container>

                            <!-- Color Column -->
                            <ng-container matColumnDef="depot">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit Depot
                              </th>
                              <td mat-cell *matCellDef="let row"> {{row.depot ? row.depot : "None"}} </td>
                            </ng-container>

                            <!-- Membership Column -->
                            <ng-container matColumnDef="membership_type">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Membership Type
                              </th>
                              <td mat-cell *matCellDef="let row"> {{row.membership_type ? row.membership_type : 'None'}} </td>
                            </ng-container>

                            <!-- Drivers License Column -->
                            <ng-container matColumnDef="drivers_licence">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Driver License
                              </th>
                              <td mat-cell *matCellDef="let row"> {{row.drivers_licence ? row.drivers_licence : 'None'}} </td>
                            </ng-container>

                            <!-- Vehicle License Column -->
                            <ng-container matColumnDef="vehicle_license">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle License
                              </th>
                              <td mat-cell *matCellDef="let row"> {{row.vehicle_license ? row.vehicle_license : 'None'}} </td>
                            </ng-container>

                            <!-- Vehicle License Expiry Column -->
                          <ng-container matColumnDef="vehicle_license_expiry">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle License Expiry
                            </th>
                            <td mat-cell *matCellDef="let row"> {{row.vehicle_license_expiry ? (row.vehicle_license_expiry | date) : "None"}} </td>
                          </ng-container>
                            
                            <!-- Contact Number Column -->
                            <ng-container matColumnDef="contact_number">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact Number
                              </th>
                              <td mat-cell *matCellDef="let row"> {{row.contact_number ? row.contact_number : 'None'}} </td>
                            </ng-container>

                            <!-- Truck Owner Name Column -->
                            <ng-container matColumnDef="truck_owner_full_name">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Truck Owner Name
                              </th>
                              <td mat-cell *matCellDef="let row"> {{row.truck_owner_full_name ? row.truck_owner_full_name : 'None'}} </td>
                            </ng-container>

                            <!-- Truck Owner Phone Number Column -->
                            <ng-container matColumnDef="truck_owner_phone_no">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Truck Owner Phone Number
                              </th>
                              <td mat-cell *matCellDef="let row"> {{row.truck_owner_phone_no ? row.truck_owner_phone_no : 'None'}} </td>
                            </ng-container>

                            <!-- Sponsored By Column -->
                            <ng-container matColumnDef="sponsored_by">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Sponsored By
                              </th>
                              <td mat-cell *matCellDef="let row"> {{row.sponsored_by ? row.sponsored_by : 'None'}} </td>
                            </ng-container>

                            <!-- Sponsored By Name Column -->
                            <ng-container matColumnDef="sponsored_by_name">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Sponsor's Name
                              </th>
                              <td mat-cell *matCellDef="let row"> {{row.sponsored_by_name ? row.sponsored_by_name : 'None'}} </td>
                            </ng-container>

                            <!-- Capture Location Column -->
                            <ng-container matColumnDef="capture_location">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header>Capture Location
                              </th>
                              <td mat-cell *matCellDef="let row"> {{row.capture_location ? row.capture_location : 'None'}} </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="updatedAt">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated On </th>
                              <td mat-cell *matCellDef="let row"> {{row.updatedAt | date}} </td>
                            </ng-container>                            

                            <!-- Color Column -->

                            <!-- Color Column -->
                            <ng-container matColumnDef="Action">
                              <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                              <td mat-cell *matCellDef="let row"> 
                                <button class="btn btn-secondary" data-toggle="modal" 
                                  [attr.data-target]="'#'+row.fingerId">View
                                </button> &nbsp;
                                <a  class="btn btn-secondary"(click)="onEdit(row._id)">Edit</a>&nbsp;
                                <a  class="btn btn-secondary" (click)="openDeleteDialog(row._id)">Delete</a> &nbsp;
                                <button class="btn badge {{row.approved? 'badge-success':'badge-danger'}}" (click) = "openConfirmDialog(row._id)">{{row.approved? 'Account Approved' : 'Approve Account'}}</button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                            </tr>
                          </table>

                          <!-- <mat-paginator [pageSizeOptions]="[20, 50, 100, 200]" showFirstLastButtons></mat-paginator> -->
                          <mat-paginator *ngIf="users.length > 0" class="mt-5" [length]="totalUsers" [pageSize]="postPerPage" [pageSizeOptions]="pageSizeOptions" (page) = onChangedPage($event)>
                          </mat-paginator>
                           <!-- Row shown when there is no matching data. -->
                          <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No data matching the filter</td>
                          </tr>
                        </div>

                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          <!-- content-wrapper ends -->
          <!-- partial:partials/_footer.html -->
          <footer class="footer">
            <div class="container-fluid clearfix">
              <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © 2020. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i>
              </span>
            </div>
          </footer>
          <!-- partial -->
        </div>
        <!-- main-panel ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>