<div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">
            <div class="row w-100">
                <div class="col-lg-4 mx-auto">
                    <div class="auto-form-wrapper">
                        <form [formGroup]="credentialsForm" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <label class="label">Password</label>
                                <div class="input-group">
                                    <input type="password" class="form-control" placeholder="*********"
                                        formControlName="password">
                                    <div class="input-group-append">
                                        <span class="input-group-text">
                                            <i class="mdi mdi-check-circle-outline"></i>
                                        </span>
                                    </div>
                                    <div *ngIf="!credentialsForm.controls.password.valid &&
              credentialsForm.controls.password.dirty" class="validator-error">
                                        <p>Enter a valid password</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="label"> Confirm Password</label>
                                <div class="input-group">
                                    <input type="password" class="form-control" placeholder="*********"
                                        formControlName="confirmpassword">
                                    <div class="input-group-append">
                                        <span class="input-group-text">
                                            <i class="mdi mdi-check-circle-outline"></i>
                                        </span>
                                    </div>
                                    <div *ngIf="!credentialsForm.controls.confirmpassword.valid &&
              credentialsForm.controls.confirmpassword.dirty" class="validator-error">
                                        <p>Password does not match</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <button [disabled]="!credentialsForm.valid" class="btn btn-dark submit-btn btn-block"
                                    [class.spinner]="loading"  type="submit">{{ title }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- content-wrapper ends -->
    </div>
    <!-- page-body-wrapper ends -->
</div>