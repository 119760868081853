<body>
  <div class="container-scroller">
    <!-- partial:partials/_navbar.html -->
    <nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
        <a class="navbar-brand brand-logo" href="#">
          <img src="assets/images/logo.png" height="80">
        </a>
      </div>
      <div class="navbar-menu-wrapper d-flex align-items-center">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item dropdown d-none d-xl-inline-block user-dropdown">
            <a class="nav-link dropdown-toggle" id="UserDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
              <img class="img-xs rounded-circle" src="../assets/images/faces/face8.jpg" alt="Profile image"> </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
              <div class="dropdown-header text-center">
                <img class="img-md rounded-circle" src="../assets/images/faces/face8.jpg" alt="Profile image">
                <p class="mb-1 mt-3 font-weight-semibold"></p>
                <p class="font-weight-light text-muted mb-0">Admin</p>
              </div>
              <a class="dropdown-item" routerLink="/admin/profile">My Profile <i
                  class="dropdown-item-icon ti-dashboard"></i></a>
              <a class="dropdown-item" style="cursor: pointer;" (click)="logout()">Sign Out<i
                  class="dropdown-item-icon ti-power-off"></i></a>
            </div>
          </li>
        </ul>
        <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
          data-toggle="offcanvas">
          <span class="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
    <!-- partial -->
    <div class="container-fluid page-body-wrapper">
      <!-- partial:partials/_sidebar.html -->
      <nav class="sidebar sidebar-offcanvas" id="sidebar">
        <ul class="nav">
          <li class="nav-item nav-profile">
            <a href="#" class="nav-link">
              <div class="profile-image">
                <img class="img-xs rounded-circle" src="../assets/images/faces/face8.jpg" alt="profile image">
                <div class="dot-indicator bg-success"></div>
              </div>
              <div class="text-wrapper">
                <p class="profile-name"></p>
                <p class="designation">Admin</p>
              </div>
            </a>
          </li>
          <li class="nav-item nav-category">Main Menu</li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/dashboard">
              <i class="menu-icon typcn typcn-document-text"></i>
              <span class="menu-title">Dashboard</span>
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/add-agent">
              <i class="menu-icon typcn typcn-user-add"></i>
              <span class="menu-title">Add Agent</span>
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/view-agent">
              <i class="menu-icon typcn typcn-eye-outline"></i>
              <span class="menu-title">View Agents</span>
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/add-exco">
              <i class="menu-icon typcn typcn-user-outline"></i>
              <span class="menu-title">Add Exco</span>
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/excos">
              <i class="menu-icon typcn typcn-eye-outline"></i>
              <span class="menu-title">View Excos</span>
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link active" routerLink="/admin/verify">
              <i class="menu-icon typcn typcn-tick"></i>
              <span class="menu-title">Verify Account</span>
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/revalidation/user/update">
              <i class="menu-icon typcn typcn-tick"></i>
              <span class="menu-title">Re-validation</span>
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/export">
              <i class="menu-icon typcn typcn-business-card"></i>
              <span class="menu-title">Export Card</span>
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/profile">
              <i class="menu-icon typcn typcn-user"></i>
              <span class="menu-title">Profile</span>
            </a>
          </li>
        </ul>
      </nav>
      <!-- partial -->
      <div class="main-panel">
        <div class="content-wrapper">
          <!-- Page Title Header Starts-->
          <div class="row page-title-header">
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 grid-margin">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex justify-content-between">
                        <h4 class="card-title mb-0 font-weight-bold">Verify User</h4>
                      </div>
                      <div>
                        <div class="row mt-5">
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label">Unique ID</label>
                              <div class="col-sm-9">
                                <input [(ngModel)]="uniqueID" type="text" class="form-control">
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group row">
                              <label class="col-sm-3 col-form-label"></label>
                              <div class="col-sm-9">
                                <button class="btn btn-secondary" color="dark" type="button"
                                  (click)="fetchUser()">Fetch</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
                        <p style="color: white">Verifying... </p>
                      </ngx-spinner>
                      <div class="col-md-12 grid-margin mt-5" *ngIf="user">
                        <div class="card">
                          <div class="card-body" #content id="demo">
                            <div>
                              <div class="verified-image">
                                <img class="user-photo rounded-circle" [src]="user.image" alt="user-image" />
                              </div>
                              <div class="mt-3">
                                <h3 class="text-center font-bold">{{ user.surname }} {{ user.firstname }}
                                  {{ user.middlename }}</h3>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-6">
                                <div class="float-left">
                                  <h4>Email : </h4>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="float-right">
                                  <h4>{{ user.email }}</h4>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-6">
                                <div class="float-left">
                                  <h4>Gender : </h4>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="float-right">
                                  <h4>{{ user.gender }}</h4>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-6">
                                <div class="float-left">
                                  <h4>D.O.B : </h4>
                                </div>

                              </div>
                              <div class="col-md-6">
                                <div class="float-right">
                                  <h4>{{ user.dob | date }}</h4>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-6">
                                <div class="float-left">
                                  <h4>Phone Number : </h4>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="float-right">
                                  <h4>+234{{ user.phone_no }}</h4>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-6">
                                <div class="float-left">
                                  <h4>Address : </h4>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="float-right">
                                  <h4>{{ user.address }}</h4>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-6">
                                <div class="float-left">
                                  <h4>Branch : </h4>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="float-right">
                                  <h4>{{ user.branch }}</h4>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-6">
                                <div class="float-left">
                                  <h4>Zone : </h4>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="float-right">
                                  <h4>{{ user.zone }}</h4>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-6">
                                <div class="float-left">
                                  <h4>State : </h4>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="float-right">
                                  <h4>{{ user.state }}</h4>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-6">
                                <div class="float-left">
                                  <h4>Unit LGA : </h4>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="float-right">
                                  <h4>{{ user.unit }}</h4>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-6">
                                <div class="float-left">
                                  <h4>Unit Depot : </h4>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="float-right">
                                  <h4>{{ user.depot? user.depot : "None" }}</h4>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-6">
                                <div class="float-left">
                                  <h4>Transportation Type : </h4>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="float-right">
                                  <h4>{{ user.transportation_type }}</h4>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-6">
                                <div class="float-left">
                                  <h4>Vehicle Number : </h4>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="float-right">
                                  <h4>{{ user.vehicleNumber }}</h4>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-6">
                                <div class="float-left">
                                  <h4>Verified ID Type : </h4>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="float-right">
                                  <h4>{{ user.verifiedIdType }}</h4>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-6">
                                <div class="float-left">
                                  <h4>Verified ID : </h4>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="float-right">
                                  <h4>{{ user.verifiedId }}</h4>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-6">
                                <div class="float-left">
                                  <h4>Finger ID : </h4>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="float-right">
                                  <h4>{{ user.fingerId }}</h4>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="user.branch != null && user.branch === 'PTD'">
                              <div *ngIf="user.membership_type != null && user.membership_type !== ''"  class="row mt-3">
                                <div class="col-md-6">
                                    <div class="float-left">
                                        <h4>Membership Type : </h4>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="float-right">
                                        <h4>{{ user.membership_type }}</h4>
                                    </div>
                                </div>
                              </div>
                              <div *ngIf="user.membership_type != null && user.membership_type !== '' && user.membership_type === 'Drivers-On-Wheels (DOW)'">
                                <div *ngIf="user.drivers_licence != null && user.drivers_licence !== ''"  class="row mt-3">
                                  <div class="col-md-6">
                                      <div class="float-left">
                                          <h4>Driver's License : </h4>
                                      </div>
                                  </div>
                                  <div class="col-md-6">
                                      <div class="float-right">
                                          <h4>{{ user.drivers_licence }}</h4>
                                      </div>
                                  </div>
                                </div>
                                <div *ngIf="user.vehicle_license != null && user.vehicle_license !== ''"  class="row mt-3">
                                  <div class="col-md-6">
                                      <div class="float-left">
                                          <h4>Vehicle License : </h4>
                                      </div>
                                  </div>
                                  <div class="col-md-6">
                                      <div class="float-right">
                                          <h4>{{ user.vehicle_license }}</h4>
                                      </div>
                                  </div>
                                </div>
                                <div *ngIf="user.vehicle_license_expiry != null && user.vehicle_license_expiry !== ''"  class="row mt-3">
                                  <div class="col-md-6">
                                      <div class="float-left">
                                          <h4>Vehicle License Expiry Date : </h4>
                                      </div>
                                  </div>
                                  <div class="col-md-6">
                                      <div class="float-right">
                                          <h4>{{ user.vehicle_license_expiry  | date}}</h4>
                                      </div>
                                  </div>
                                </div>
                                <div *ngIf="user.contact_number != null && user.contact_number !== ''"  class="row mt-3">
                                  <div class="col-md-6">
                                      <div class="float-left">
                                          <h4>Valid Contact Number : </h4>
                                      </div>
                                  </div>
                                  <div class="col-md-6">
                                      <div class="float-right">
                                          <h4>{{ user.contact_number }}</h4>
                                      </div>
                                  </div>
                                </div>
                                <div *ngIf="user.truck_owner_full_name != null && user.truck_owner_full_name !== ''"  class="row mt-3">
                                  <div class="col-md-6">
                                      <div class="float-left">
                                          <h4>Valid Full Name of Tanker/Truck Owner : </h4>
                                      </div>
                                  </div>
                                  <div class="col-md-6">
                                      <div class="float-right">
                                          <h4>{{ user.truck_owner_full_name }}</h4>
                                      </div>
                                  </div>
                                </div>

                                <div *ngIf="user.truck_owner_phone_no != null && user.truck_owner_phone_no !== ''"  class="row mt-3">
                                  <div class="col-md-6">
                                      <div class="float-left">
                                          <h4>Valid Phone Number of Tanker/Truck Owner : </h4>
                                      </div>
                                  </div>
                                  <div class="col-md-6">
                                      <div class="float-right">
                                          <h4>{{ user.truck_owner_phone_no }}</h4>
                                      </div>
                                  </div>
                                </div>

                                <div *ngIf="user.sponsored_by != null && user.sponsored_by !== ''"  class="row mt-3">
                                  <div class="col-md-6">
                                      <div class="float-left">
                                          <h4>Endorsed/Sponsored By : </h4>
                                      </div>
                                  </div>
                                  <div class="col-md-6">
                                      <div class="float-right">
                                          <h4>{{ user.sponsored_by }}</h4>
                                      </div>
                                  </div>
                                </div>

                                <div *ngIf="user.sponsored_by_name != null && user.sponsored_by_name !== ''"  class="row mt-3">
                                  <div class="col-md-6">
                                      <div class="float-left">
                                          <h4>Sponsor's Name : </h4>
                                      </div>
                                  </div>
                                  <div class="col-md-6">
                                      <div class="float-right">
                                          <h4>{{ user.sponsored_by_name }}</h4>
                                      </div>
                                  </div>
                                </div>

                                <div *ngIf="user.capture_location != null && user.capture_location !== ''"  class="row mt-3">
                                  <div class="col-md-6">
                                      <div class="float-left">
                                          <h4>Point/Location of Capture : </h4>
                                      </div>
                                  </div>
                                  <div class="col-md-6">
                                      <div class="float-right">
                                          <h4>{{ user.capture_location }}</h4>
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            
                            <p class="card-description mt-5 font-weight-bold">Next of Kin Information</p>
                            <div class="row mt-5">
                              <div class="col-md-6">
                                <div class="float-left">
                                  <h4>Name : </h4>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="float-right">
                                  <h4>{{ user.next_of_kin_name }}</h4>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-6">
                                <div class="float-left">
                                  <h4>Phone Number: </h4>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="float-right">
                                  <h4>{{ user.next_of_kin_phone_no }}</h4>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-6">
                                <div class="float-left">
                                  <h4>Address : </h4>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="float-right">
                                  <h4>{{ user.next_of_kin_address }}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <!-- <div class="col-md-6">
                                        <button class="btn btn-secondary" color="dark" type="button" (click)="makePDF()">Save as PDF</button>
                                    </div> -->
                            <div class="col-md-6">
                              <div class="float-right">
                                <button [useExistingCss]="true" class="btn btn-secondary" color="dark" type="button"
                                  printSectionId="demo" ngxPrint>Print</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
        <footer class="footer">
          <div class="container-fluid clearfix">
            <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © 2020. All rights
              reserved.</span>
            <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i
                class="mdi mdi-heart text-danger"></i>
            </span>
          </div>
        </footer>
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>