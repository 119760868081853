<!-- partial -->
<div class="main-panel">
    <div class="content-wrapper">
      <!-- Page Title Header Starts-->
      <div class="row page-title-header">
      </div>
      <div class="row">
        <div class="col-md-12">

          <div class="col-md-12 grid-margin">
            <div class="card">
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <h4 class="card-title mb-0 font-weight-bold">Total Number Of Registered Users</h4>
                </div>
                <div class="row mt-5">
            
                  <div class="col-md-6 grid-margin stretch-card average-price-card">
                    <div class="card text-white">
                      <div class="card-body">
                        <div class="d-flex justify-content-between pb-2 align-items-center">
                          <h2 class="font-weight-semibold mb-0">{{ userCount? userCount : 0 }}</h2>
                          <div class="icon-holder">
                            <i class="mdi mdi-account-check-outline"></i>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between">
                          <h5 class="font-weight-semibold mb-0">Users</h5>
                        </div>
                      </div>
                    </div>
                  </div>
      
                </div>

                <h4 class="card-title mb-0 mt-5 font-weight-bold">Total Number Of Users By Branch</h4>

                <div class="row mt-5">

                  <div class="col-md-3 grid-margin stretch-card average-price-card">
                    <div class="card text-white">
                      <div class="card-body">
                        <div class="d-flex justify-content-between pb-2 align-items-center">
                          <h2 class="font-weight-semibold mb-0">{{ PTDCount? PTDCount: 0 }}</h2>
                          <div class="icon-holder">
                            <i class="mdi mdi-account-check-outline"></i>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between">
                          <h5 class="font-weight-semibold mb-0">PTD</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 grid-margin stretch-card average-price-card">
                    <div class="card text-white">
                      <div class="card-body">
                        <div class="d-flex justify-content-between pb-2 align-items-center">
                          <h2 class="font-weight-semibold mb-0">{{ IMBCount? IMBCount : 0 }}</h2>
                          <div class="icon-holder">
                            <i class="mdi mdi-account-check-outline"></i>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between">
                          <h5 class="font-weight-semibold mb-0">IMB</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 grid-margin stretch-card average-price-card">
                    <div class="card text-white">
                      <div class="card-body">
                        <div class="d-flex justify-content-between pb-2 align-items-center">
                          <h2 class="font-weight-semibold mb-0">{{ ELDCount ? ELDCount : 0 }}</h2>
                          <div class="icon-holder">
                            <i class="mdi mdi-account-check-outline"></i>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between">
                          <h5 class="font-weight-semibold mb-0">ELD</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 grid-margin stretch-card average-price-card">
                    <div class="card text-white">
                      <div class="card-body">
                        <div class="d-flex justify-content-between pb-2 align-items-center">
                          <h2 class="font-weight-semibold mb-0">{{ LPGARCount? LPGARCount: 0 }}</h2>
                          <div class="icon-holder">
                            <i class="mdi mdi-account-check-outline"></i>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between">
                          <h5 class="font-weight-semibold mb-0">LPGAR</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 grid-margin stretch-card average-price-card">
                    <div class="card text-white">
                      <div class="card-body">
                        <div class="d-flex justify-content-between pb-2 align-items-center">
                          <h2 class="font-weight-semibold mb-0">{{ OGSCount? OGSCount : 0 }}</h2>
                          <div class="icon-holder">
                            <i class="mdi mdi-account-check-outline"></i>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between">
                          <h5 class="font-weight-semibold mb-0">OGS</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 grid-margin stretch-card average-price-card">
                    <div class="card text-white">
                      <div class="card-body">
                        <div class="d-flex justify-content-between pb-2 align-items-center">
                          <h2 class="font-weight-semibold mb-0">{{ SUTAKEPCount? SUTAKEPCount : 0 }}</h2>
                          <div class="icon-holder">
                            <i class="mdi mdi-account-check-outline"></i>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between">
                          <h5 class="font-weight-semibold mb-0">SUTAKEP</h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 grid-margin stretch-card average-price-card">
                    <div class="card text-white">
                      <div class="card-body">
                        <div class="d-flex justify-content-between pb-2 align-items-center">
                          <h2 class="font-weight-semibold mb-0">{{ PSWCount? PSWCount : 0 }}</h2>
                          <div class="icon-holder">
                            <i class="mdi mdi-account-check-outline"></i>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between">
                          <h5 class="font-weight-semibold mb-0">PSW</h5>
                        </div>
                      </div>
                    </div>
                  </div>
      
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
        <p style="color: white">Please wait... </p>
      </ngx-spinner>
    </div>
    <!-- content-wrapper ends -->
    <!-- partial:partials/_footer.html -->
    <footer class="footer">
      <div class="container-fluid clearfix">
        <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © 2023. All rights reserved.</span>
        <!-- <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i>
        </span> -->
      </div>
    </footer>
    <!-- partial -->
  </div>
