import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { AgentService } from '../../agent/agent.service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { User } from 'src/app/shared/users.model';
import { AdminService } from '../admin.service';
import { finalize } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { externalParameters, externalBranches, externalMembershipType, externalSponsporedType } from '../../agent/capture/state-file';
import { AdminAuthService } from 'src/app/auth/admin.auth.service';
const _zones = externalParameters.zones;
const _branches = externalBranches.branches;
const _membershipType = externalMembershipType;
const _sponsoredType = externalSponsporedType;

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
  title: string = "Take Picture";
  user: User;
  userId: string;
  agentForm: FormGroup;
  membershipTypes: string[] = _membershipType;
  sponsoredTypes: string[] = _sponsoredType;
  branchType: string = "";
  membership: string = "";
  ptd_endorsed_type: string = "";
  sponsoredType: string = "";

  zones: string[] = _zones;
  branches: string[] = _branches;
  states: any;
  selectedStates: any;
  lga: any[];
  depots: any[];
  // toggle webcam on/off
  public showWebcam = false;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  public triggerSnapshot(): void {
    this.trigger.next();
    this.toggleWebcam();
  }

  toggleWebcam() {
    this.showWebcam = !this.showWebcam;
    if (this.showWebcam == true) {
      this.title = "Switch Camera Off";
    }
    else {
      this.title = "Take Picture";
    }
  }


  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.showWebcam = !this.showWebcam;
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  constructor(
    private SpinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private agentService: AgentService,
    private adminAuthService: AdminAuthService,
    public formBuilder: FormBuilder,
    private adminService: AdminService,
    private router: Router
  ) {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
  }

  ngOnInit(): void {
    this.init();
    this.route.params.subscribe(
      (params: Params) => {
        this.SpinnerService.show();
        if (params['user']) {
          this.userId = params['user'];
          this.adminService.getUserById(this.userId)
            .pipe(finalize(() => {
              this.SpinnerService.hide();
            }))
            .subscribe(response => {
              this.user = response.user;
              this.agentForm.setValue({
                firstname: response.user.firstname ? response.user.firstname : '',
                uniqueId: response.user.uniqueId ? response.user.uniqueId : '',
                middlename: response.user.middlename ? response.user.middlename : '',
                verifiedId: response.user.verifiedId ? response.user.verifiedId : '',
                vehicleNumber: response.user.vehicleNumber ? response.user.vehicleNumber : '',
                verifiedIdType: response.user.verifiedIdType ? response.user.verifiedIdType : '',
                transportation_type: response.user.transportation_type ? response.user.transportation_type : '',
                surname: response.user.surname ? response.user.surname : '',
                gender: response.user.gender ? response.user.gender : '',
                address: response.user.address ? response.user.address : '',
                zone: response.user.zone ? response.user.zone : '',
                branch: response.user.branch ? response.user.branch : '',
                dob: response.user.dob ? response.user.dob : '',
                unit: response.user.unit ? response.user.unit : '',
                depot: response.user.depot ? response.user.depot : '',
                phone_no: response.user.phone_no ? response.user.phone_no : '',
                state: response.user.state ? response.user.state : '',
                next_of_kin_name: response.user.next_of_kin_name ? response.user.next_of_kin_name : '',
                next_of_kin_address: response.user.next_of_kin_address ? response.user.next_of_kin_address : '',
                next_of_kin_phone_no: response.user.next_of_kin_phone_no ? response.user.next_of_kin_phone_no : '',
                membership_type: response.user.membership_type ?? '',
                drivers_licence: response.user.drivers_licence ?? '',
                vehicle_license: response.user.vehicle_license ?? '',
                vehicle_license_expiry: response.user.vehicle_license_expiry ?? '',
                contact_number: response.user.contact_number ?? '',
                truck_owner_full_name: response.user.truck_owner_full_name ?? '',
                truck_owner_phone_no: response.user.truck_owner_phone_no ?? '',
                sponsored_by: response.user.sponsored_by ?? '',
                sponsored_by_name: response.user.sponsored_by_name ?? '',
                capture_location: response.user.capture_location ?? '',
                image: '',
                email: response.user.email ? response.user.email : ''
              });
              this.branchType = response.user.branch ?? '';
              this.membership = response.user.membership_type ?? '';
              this.sponsoredType = response.user.sponsored_by ?? '';
              if (this.agentForm.value.zone) {
                this.selectZone(this.agentForm.value.zone);
                if (this.agentForm.value.state) {
                  this.selectState(this.agentForm.value.state);
                }
              }
            });
        }
      }
    );
  }

  private init() {
    this.agentForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      uniqueId: ['', Validators.nullValidator],
      middlename: ['', Validators.required],
      verifiedId: ['', Validators.required],
      vehicleNumber: ['', Validators.required],
      verifiedIdType: ['', Validators.required],
      transportation_type: ['', Validators.required],
      surname: ['', Validators.required],
      gender: ['', Validators.required],
      address: ['', Validators.required],
      zone: ['', Validators.required],
      branch: ['', Validators.required],
      dob: ['', Validators.required],
      unit: ['', Validators.required],
      depot: ['', Validators.required],
      phone_no: ['', Validators.compose([Validators.required, Validators.pattern(/^[0-9]+[0-9]*$/)])],
      state: ['', Validators.required],
      next_of_kin_name: ['', Validators.required],
      next_of_kin_address: ['', Validators.required],
      next_of_kin_phone_no: ['', Validators.required],
      image: ['', Validators.nullValidator],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      membership_type: ['', this.conditionalRequiredValidatorForMembershipType],
      drivers_licence: ['', this.conditionalRequiredValidatorForMembershipTypeDOW],
      vehicle_license: ['', this.conditionalRequiredValidatorForMembershipTypeDOW],
      vehicle_license_expiry: ['', this.conditionalRequiredValidatorForMembershipTypeDOW],
      contact_number: ['', Validators.compose([this.conditionalRequiredValidatorForMembershipTypeDOW, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(/^[0-9]+[0-9]*$/)])],
      truck_owner_full_name: ['', this.conditionalRequiredValidatorForMembershipTypeDOW],
      truck_owner_phone_no: ['', Validators.compose([this.conditionalRequiredValidatorForMembershipTypeDOW, Validators.maxLength(10), Validators.minLength(10), Validators.pattern(/^[0-9]+[0-9]*$/)])],
      sponsored_by: ['', this.conditionalRequiredValidatorForMembershipTypeDOW],
      sponsored_by_name: ['', this.conditionalRequiredValidatorForMembershipTypeDOW],
      capture_location: ['', this.conditionalRequiredValidatorForMembershipTypeDOW],
    });
  }

  conditionalRequiredValidatorForMembershipType: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
    if (this.branchType === 'PTD' && Validators.required(control)) {
      return { 'required': true };
    }
    return null;
  };

  conditionalRequiredValidatorForMembershipTypeDOW: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
    if (this.membership === this.membershipTypes[1] && Validators.required(control)) {
      return { 'required': true };
    }
    return null;
  };

  selectBranch(value) {
    this.branchType = value;
    this.agentForm.get('membership_type').setValidators(this.conditionalRequiredValidatorForMembershipType);
    this.agentForm.get('membership_type').updateValueAndValidity();
  }

  selectPTDType(value) {
    this.membership = value;
    const fieldsToUpdate = [
      'drivers_licence',
      'vehicle_license',
      'vehicle_license_expiry',
      'contact_number',
      'truck_owner_full_name',
      'truck_owner_phone_no',
      'sponsored_by',
      'capture_location',
      'sponsored_by_name'
    ];

    fieldsToUpdate.forEach(field => {
      const control = this.agentForm.get(field);
      control.setValidators(this.conditionalRequiredValidatorForMembershipTypeDOW);
      control.updateValueAndValidity();
    });
  }

  selectPTDSponsoredType(value) {
    this.sponsoredType = value;
  }


  selectZone(value: any) {
    if (value == "Kaduna") {
      this.states = externalParameters.kaduna_states;
      this.depots = externalParameters.kaduna_states[externalParameters.kaduna_states.length - 1].depots;
    } else if (value == "Lagos") {
      this.states = externalParameters.lagos_states;
      this.depots = externalParameters.lagos_states[externalParameters.lagos_states.length - 1].depots;
    } else if (value == "Warri") {
      this.states = externalParameters.warri_states;
      this.depots = externalParameters.warri_states[externalParameters.warri_states.length - 1].depots;
    } else {
      this.states = externalParameters.port_harcourt_zones;
      this.depots = externalParameters.port_harcourt_zones[externalParameters.port_harcourt_zones.length - 1].depots;
    }
  }

  selectState(value) {
    const myStates = this.states.find(state => state.state === value);
    if (this.agentForm.value.zone == 'PTD') {
      this.lga = myStates.units;
    }
    else {
      this.lga = myStates.lga;
    }
  }

  onSubmit() {
    if (this.webcamImage != null) {
      this.agentForm.value.image = this.webcamImage.imageAsDataUrl;
    } else {
      this.agentForm.value.image = null;
    }
    this.SpinnerService.show();
    this.adminService.editUser(this.agentForm.value, this.userId)
      .subscribe(response => {
        this.SpinnerService.hide();
        this.router.navigateByUrl('/admin/dashboard');
      }, error => {
        this.SpinnerService.hide();
      })
    // this.router.navigateByUrl('/admin/dashboard');
    // this.agentService.createUser(this.agentForm.value);
  }

  logout() {
    this.adminAuthService.logout();
  }

}
