export const externalBranches =
{
  branches: [
    "PTD",
    "IMB",
    "ELD",
    "JEWOG",
    "LPGAR",
    "OGS",
    "SUTAKEP",
    "PSW"
  ]
};

export const  externalMembershipType = [
  "Ordinary PTD Member",
  "Drivers-On-Wheels (DOW)"
];

export const  externalSponsporedType = [
  "Zonal Secretary",
  "Unit Chairman",
  "Unit Secretary"
];

export const externalParameters = {
  zones: [
    "Lagos",
    "Port Harcourt",
    "Warri",
    "Kaduna",
  ],

  kaduna_states: [
    {
      state: "Abuja",
      lga: [
        "Abaji",
        "Abuja Municipal",
        "Bwari",
        "Gwagwalada",
        "Kuje",
        "Kwali"
      ],
      units: [
        "N.N.P.C GOMBE",
        "N.N.P.C GUSAU",
        "N.N.P.C GUSAU",
        "N.N.P.C KADUNA REFINERY",
        "N.N.P.C KANO",
        "N.N.P.C MAIDUGURI",
        "N.N.P.C MINNA",
        "N.N.P.C SULEJA",
        "N.N.P.C YOLA"
      ]
    },
    {
      state: "Adamawa",
      lga: [
        "Demsa",
        "Fufore",
        "Ganaye",
        "Gireri",
        "Gombi",
        "Guyuk",
        "Hong",
        "Jada",
        "Lamurde",
        "Madagali",
        "Maiha",
        "Mayo-Belwa",
        "Michika",
        "Mubi North",
        "Mubi South",
        "Numan",
        "Shelleng",
        "Song",
        "Toungo",
        "Yola North",
        "Yola South"
      ],
      units: [
        "N.N.P.C GOMBE",
        "N.N.P.C GUSAU",
        "N.N.P.C GUSAU",
        "N.N.P.C KADUNA REFINERY",
        "N.N.P.C KANO",
        "N.N.P.C MAIDUGURI",
        "N.N.P.C MINNA",
        "N.N.P.C SULEJA",
        "N.N.P.C YOLA"
      ]
    },
    {
      state: "Bauchi",
      lga: [
        "Alkaleri",
        "Bauchi",
        "Bogoro",
        "Damban",
        "Darazo",
        "Dass",
        "Ganjuwa",
        "Giade",
        "Itas/Gadau",
        "Jama'are",
        "Katagum",
        "Kirfi",
        "Misau",
        "Ningi",
        "Shira",
        "Tafawa-Balewa",
        "Toro",
        "Warji",
        "Zaki"
      ],
      units: [
        "N.N.P.C GOMBE",
        "N.N.P.C GUSAU",
        "N.N.P.C GUSAU",
        "N.N.P.C KADUNA REFINERY",
        "N.N.P.C KANO",
        "N.N.P.C MAIDUGURI",
        "N.N.P.C MINNA",
        "N.N.P.C SULEJA",
        "N.N.P.C YOLA"
      ]
    },
    {
      state: "Borno",
      lga: [
        "Abadam",
        "Askira/Uba",
        "Bama",
        "Bayo",
        "Biu",
        "Chibok",
        "Damboa",
        "Dikwa",
        "Gubio",
        "Guzamala",
        "Gwoza",
        "Hawul",
        "Jere",
        "Kaga",
        "Kala/Balge",
        "Konduga",
        "Kukawa",
        "Kwaya Kusar",
        "Mafa",
        "Magumeri",
        "Maiduguri",
        "Marte",
        "Mobbar",
        "Monguno",
        "Ngala",
        "Nganzai",
        "Shani"
      ],
      units: [
        "N.N.P.C GOMBE",
        "N.N.P.C GUSAU",
        "N.N.P.C GUSAU",
        "N.N.P.C KADUNA REFINERY",
        "N.N.P.C KANO",
        "N.N.P.C MAIDUGURI",
        "N.N.P.C MINNA",
        "N.N.P.C SULEJA",
        "N.N.P.C YOLA"
      ]
    },
    {
      state: "Gombe",
      lga: [
        "Akko",
        "Balanga",
        "Billiri",
        "Dukku",
        "Kaltungo",
        "Kwami",
        "Shomgom",
        "Funakaye",
        "Gombe",
        "Nafada/Bajoga",
        "Yamaltu/Delta."
      ],
      units: [
        "N.N.P.C GOMBE",
        "N.N.P.C GUSAU",
        "N.N.P.C GUSAU",
        "N.N.P.C KADUNA REFINERY",
        "N.N.P.C KANO",
        "N.N.P.C MAIDUGURI",
        "N.N.P.C MINNA",
        "N.N.P.C SULEJA",
        "N.N.P.C YOLA"
      ]
    },
    {
      state: "Jigawa",
      lga: [
        "Auyo",
        "Babura",
        "Birni Kudu",
        "Biriniwa",
        "Buji",
        "Dutse",
        "Gagarawa",
        "Garki",
        "Gumel",
        "Guri",
        "Gwaram",
        "Gwiwa",
        "Hadejia",
        "Jahun",
        "Kafin Hausa",
        "Kaugama Kazaure",
        "Kiri Kasamma",
        "Kiyawa",
        "Maigatari",
        "Malam Madori",
        "Miga",
        "Ringim",
        "Roni",
        "Sule-Tankarkar",
        "Taura",
        "Yankwashi"
      ],
      units: [
        "N.N.P.C GOMBE",
        "N.N.P.C GUSAU",
        "N.N.P.C GUSAU",
        "N.N.P.C KADUNA REFINERY",
        "N.N.P.C KANO",
        "N.N.P.C MAIDUGURI",
        "N.N.P.C MINNA",
        "N.N.P.C SULEJA",
        "N.N.P.C YOLA"
      ]
    },
    {
      state: "Kano",
      lga: [
        "Ajingi",
        "Albasu",
        "Bagwai",
        "Bebeji",
        "Bichi",
        "Bunkure",
        "Dala",
        "Dambatta",
        "Dawakin Kudu",
        "Dawakin Tofa",
        "Doguwa",
        "Fagge",
        "Gabasawa",
        "Garko",
        "Garum",
        "Mallam",
        "Gaya",
        "Gezawa",
        "Gwale",
        "Gwarzo",
        "Kabo",
        "Kano Municipal",
        "Karaye",
        "Kibiya",
        "Kiru",
        "kumbotso",
        "Kunchi",
        "Kura",
        "Madobi",
        "Makoda",
        "Minjibir",
        "Nasarawa",
        "Rano",
        "Rimin Gado",
        "Rogo",
        "Shanono",
        "Sumaila",
        "Takali",
        "Tarauni",
        "Tofa",
        "Tsanyawa",
        "Tudun Wada",
        "Ungogo",
        "Warawa",
        "Wudil"
      ],
      units: [
        "N.N.P.C GOMBE",
        "N.N.P.C GUSAU",
        "N.N.P.C GUSAU",
        "N.N.P.C KADUNA REFINERY",
        "N.N.P.C KANO",
        "N.N.P.C MAIDUGURI",
        "N.N.P.C MINNA",
        "N.N.P.C SULEJA",
        "N.N.P.C YOLA"
      ]
    },
    {
      state: "Kaduna",
      lga: [
        "Birni-Gwari",
        "Chikun",
        "Giwa",
        "Igabi",
        "Ikara",
        "jaba",
        "Jema'a",
        "Kachia",
        "Kaduna North",
        "Kaduna South",
        "Kagarko",
        "Kajuru",
        "Kaura",
        "Kauru",
        "Kubau",
        "Kudan",
        "Lere",
        "Makarfi",
        "Sabon-Gari",
        "Sanga",
        "Soba",
        "Zango-Kataf",
        "Zaria"
      ],
      units: [
        "N.N.P.C GOMBE",
        "N.N.P.C GUSAU",
        "N.N.P.C GUSAU",
        "N.N.P.C KADUNA REFINERY",
        "N.N.P.C KANO",
        "N.N.P.C MAIDUGURI",
        "N.N.P.C MINNA",
        "N.N.P.C SULEJA",
        "N.N.P.C YOLA"
      ]
    },
    {
      state: "Katsina",
      lga: [
        "Bakori",
        "Batagarawa",
        "Batsari",
        "Baure",
        "Bindawa",
        "Charanchi",
        "Dandume",
        "Danja",
        "Dan Musa",
        "Daura",
        "Dutsi",
        "Dutsin-Ma",
        "Faskari",
        "Funtua",
        "Ingawa",
        "Jibia",
        "Kafur",
        "Kaita",
        "Kankara",
        "Kankia",
        "Katsina",
        "Kurfi",
        "Kusada",
        "Mai'Adua",
        "Malumfashi",
        "Mani",
        "Mashi",
        "Matazuu",
        "Musawa",
        "Rimi",
        "Sabuwa",
        "Safana",
        "Sandamu",
        "Zango"
      ],
      units: [
        "N.N.P.C GOMBE",
        "N.N.P.C GUSAU",
        "N.N.P.C GUSAU",
        "N.N.P.C KADUNA REFINERY",
        "N.N.P.C KANO",
        "N.N.P.C MAIDUGURI",
        "N.N.P.C MINNA",
        "N.N.P.C SULEJA",
        "N.N.P.C YOLA"
      ]
    },
    {
      state: "Kebbi",
      lga: [
        "Aleiro",
        "Arewa-Dandi",
        "Argungu",
        "Augie",
        "Bagudo",
        "Birnin Kebbi",
        "Bunza",
        "Dandi",
        "Fakai",
        "Gwandu",
        "Jega",
        "Kalgo",
        "Koko/Besse",
        "Maiyama",
        "Ngaski",
        "Sakaba",
        "Shanga",
        "Suru",
        "Wasagu/Danko",
        "Yauri",
        "Zuru"
      ],
      units: [
        "N.N.P.C GOMBE",
        "N.N.P.C GUSAU",
        "N.N.P.C GUSAU",
        "N.N.P.C KADUNA REFINERY",
        "N.N.P.C KANO",
        "N.N.P.C MAIDUGURI",
        "N.N.P.C MINNA",
        "N.N.P.C SULEJA",
        "N.N.P.C YOLA"
      ]
    },
    {
      state: "Nasarawa",
      lga: [
        "Akwanga",
        "Awe",
        "Doma",
        "Karu",
        "Keana",
        "Keffi",
        "Kokona",
        "Lafia",
        "Nasarawa",
        "Nasarawa-Eggon",
        "Obi",
        "Toto",
        "Wamba"
      ],
      units: [
        "N.N.P.C GOMBE",
        "N.N.P.C GUSAU",
        "N.N.P.C GUSAU",
        "N.N.P.C KADUNA REFINERY",
        "N.N.P.C KANO",
        "N.N.P.C MAIDUGURI",
        "N.N.P.C MINNA",
        "N.N.P.C SULEJA",
        "N.N.P.C YOLA"
      ]
    },
    {
      state: "Niger",
      lga: [
        "Agaie",
        "Agwara",
        "Bida",
        "Borgu",
        "Bosso",
        "Chanchaga",
        "Edati",
        "Gbako",
        "Gurara",
        "Katcha",
        "Kontagora",
        "Lapai",
        "Lavun",
        "Magama",
        "Mariga",
        "Mashegu",
        "Mokwa",
        "Muya",
        "Pailoro",
        "Rafi",
        "Rijau",
        "Shiroro",
        "Suleja",
        "Tafa",
        "Wushishi"
      ],
      units: [
        "N.N.P.C GOMBE",
        "N.N.P.C GUSAU",
        "N.N.P.C GUSAU",
        "N.N.P.C KADUNA REFINERY",
        "N.N.P.C KANO",
        "N.N.P.C MAIDUGURI",
        "N.N.P.C MINNA",
        "N.N.P.C SULEJA",
        "N.N.P.C YOLA"
      ]
    },
    {
      state: "Plateau",
      lga: [
        "Barikin Ladi",
        "Bassa",
        "Bokkos",
        "Jos East",
        "Jos North",
        "Jos South",
        "Kanam",
        "Kanke",
        "Langtang North",
        "Langtang South",
        "Mangu",
        "Mikang",
        "Pankshin",
        "Qua'an Pan",
        "Riyom",
        "Shendam",
        "Wase"
      ],
      units: [
        "N.N.P.C GOMBE",
        "N.N.P.C GUSAU",
        "N.N.P.C GUSAU",
        "N.N.P.C KADUNA REFINERY",
        "N.N.P.C KANO",
        "N.N.P.C MAIDUGURI",
        "N.N.P.C MINNA",
        "N.N.P.C SULEJA",
        "N.N.P.C YOLA"
      ]
    },
    {
      state: "Sokoto",
      lga: [
        "Binji",
        "Bodinga",
        "Dange-shnsi",
        "Gada",
        "Goronyo",
        "Gudu",
        "Gawabawa",
        "Illela",
        "Isa",
        "Kware",
        "kebbe",
        "Rabah",
        "Sabon birni",
        "Shagari",
        "Silame",
        "Sokoto North",
        "Sokoto South",
        "Tambuwal",
        "Tqngaza",
        "Tureta",
        "Wamako",
        "Wurno",
        "Yabo"
      ],
    },
    {
      state: "Taraba",
      lga: [
        "Ardo-kola",
        "Bali",
        "Donga",
        "Gashaka",
        "Cassol",
        "Ibi",
        "Jalingo",
        "Karin-Lamido",
        "Kurmi",
        "Lau",
        "Sardauna",
        "Takum",
        "Ussa",
        "Wukari",
        "Yorro",
        "Zing"
      ],
      units: [
        "N.N.P.C GOMBE",
        "N.N.P.C GUSAU",
        "N.N.P.C GUSAU",
        "N.N.P.C KADUNA REFINERY",
        "N.N.P.C KANO",
        "N.N.P.C MAIDUGURI",
        "N.N.P.C MINNA",
        "N.N.P.C SULEJA",
        "N.N.P.C YOLA"
      ]
    },
    {
      state: "Yobe",
      lga: [
        "Bade",
        "Bursari",
        "Damaturu",
        "Fika",
        "Fune",
        "Geidam",
        "Gujba",
        "Gulani",
        "Jakusko",
        "Karasuwa",
        "Karawa",
        "Machina",
        "Nangere",
        "Nguru Potiskum",
        "Tarmua",
        "Yunusari",
        "Yusufari"
      ],
      units: [
        "N.N.P.C GOMBE",
        "N.N.P.C GUSAU",
        "N.N.P.C GUSAU",
        "N.N.P.C KADUNA REFINERY",
        "N.N.P.C KANO",
        "N.N.P.C MAIDUGURI",
        "N.N.P.C MINNA",
        "N.N.P.C SULEJA",
        "N.N.P.C YOLA"
      ]
    },
    {
      state: "Zamfara",
      lga: [
        "Anka",
        "Bakura",
        "Birnin Magaji",
        "Bukkuyum",
        "Bungudu",
        "Gummi",
        "Gusau",
        "Kaura",
        "Namoda",
        "Maradun",
        "Maru",
        "Shinkafi",
        "Talata Mafara",
        "Tsafe",
        "Zurmi"
      ],
      units: [
        "N.N.P.C GOMBE",
        "N.N.P.C GUSAU",
        "N.N.P.C GUSAU",
        "N.N.P.C KADUNA REFINERY",
        "N.N.P.C KANO",
        "N.N.P.C MAIDUGURI",
        "N.N.P.C MINNA",
        "N.N.P.C SULEJA",
        "N.N.P.C YOLA"
      ]
    },
    {
      depots: [
        "DANMARMA",
        "N.N.P.C GOMBE",
        "N.N.P.C GUSAU",
        "N.N.P.C JOS",
        "N.N.P.C KADUNA REFINERY",
        "N.N.P.C KANO",
        "N.N.P.C MAIDUGURI",
        "N.N.P.C MINNA",
        "N.N.P.C SULEJA",
        "N.N.P.C YOLA",
      ]
    }
  ],

  warri_states: [
    {
      state: "Edo",
      lga: [
        "Esan North-East",
        "Esan Central",
        "Esan West",
        "Egor",
        "Ukpoba",
        "Central",
        "Etsako Central",
        "Igueben",
        "Oredo",
        "Ovia SouthWest",
        "Ovia South-East",
        "Orhionwon",
        "Uhunmwonde",
        "Etsako East",
        "Esan South-East"
      ],
      units: [
        "A&E UNIT, WARRI",
        "AIDA UNIT, WARRI",
        "BLUEFIN UNIT, WARRI",
        "CYBERNETICS, OGHARA",
        "KEONAMEX UNIT, WARRI",
        "I.N.T TOWER, KOKO, WARRI",
        "LANAKA UNIT/A.Y.M SHAFA",
        "MATRIX LPG GAS, WARRI",
        "MATRIX UNIT",
        "N.N.P.C BENIN UNIT",
        "N.N.P.C ORE",
        "N.N.P.C WARRI REFINERY",
        "NEPAL UNIT, OGHARA",
        "OGORODE SAPELE",
        "OKOMU RED OIL, BENIN",
        "OPTIMA UNIT, KOKO",
        "OTHNIEL UNIT, OGHARA",
        "PINNACLE UNIT, WARRI",
        "PRESSCO RED OIL",
        "PRUDENT UNIT",
        "RAIN OIL, OGHARA",
        "TAURUS UNIT, KOKO",
        "TOTAL UNIT, KOKO"
      ]
    },
    {
      state: "Delta",
      lga: [
        "Oshimili",
        "Aniocha",
        "Aniocha South",
        "Ika South",
        "Ika North-East",
        "Ndokwa West",
        "Ndokwa East",
        "Isoko south",
        "Isoko North",
        "Bomadi",
        "Burutu",
        "Ughelli South",
        "Ughelli North",
        "Ethiope West",
        "Ethiope East",
        "Sapele",
        "Okpe",
        "Warri North",
        "Warri South",
        "Uvwie",
        "Udu",
        "Warri Central",
        "Ukwani",
        "Oshimili North",
        "Patani"
      ],
      units: [
        "A&E UNIT, WARRI",
        "AIDA UNIT, WARRI",
        "BLUEFIN UNIT, WARRI",
        "CYBERNETICS, OGHARA",
        "KEONAMEX UNIT, WARRI",
        "I.N.T TOWER, KOKO, WARRI",
        "LANAKA UNIT/A.Y.M SHAFA",
        "MATRIX LPG GAS, WARRI",
        "MATRIX UNIT",
        "N.N.P.C BENIN UNIT",
        "N.N.P.C ORE",
        "N.N.P.C WARRI REFINERY",
        "NEPAL UNIT, OGHARA",
        "OGORODE SAPELE",
        "OKOMU RED OIL, BENIN",
        "OPTIMA UNIT, KOKO",
        "OTHNIEL UNIT, OGHARA",
        "PINNACLE UNIT, WARRI",
        "PRESSCO RED OIL",
        "PRUDENT UNIT",
        "RAIN OIL, OGHARA",
        "TAURUS UNIT, KOKO",
        "TOTAL UNIT, KOKO"
      ]
    },
    {
      state: "Kogi",
      lga: [
        "Adavi",
        "Ajaokuta",
        "Ankpa",
        "Bassa",
        "Dekina",
        "Ibaji",
        "Idah",
        "Igalamela-Odolu",
        "Ijumu",
        "Kabba/Bunu",
        "Kogi",
        "Lokoja",
        "Mopa-Muro",
        "Ofu",
        "Ogori/Mangongo",
        "Okehi",
        "Okene",
        "Olamabolo",
        "Omala",
        "Yagba East",
        "Yagba West"
      ],
      units: [
        "A&E UNIT, WARRI",
        "AIDA UNIT, WARRI",
        "BLUEFIN UNIT, WARRI",
        "CYBERNETICS, OGHARA",
        "KEONAMEX UNIT, WARRI",
        "I.N.T TOWER, KOKO, WARRI",
        "LANAKA UNIT/A.Y.M SHAFA",
        "MATRIX LPG GAS, WARRI",
        "MATRIX UNIT",
        "N.N.P.C BENIN UNIT",
        "N.N.P.C ORE",
        "N.N.P.C WARRI REFINERY",
        "NEPAL UNIT, OGHARA",
        "OGORODE SAPELE",
        "OKOMU RED OIL, BENIN",
        "OPTIMA UNIT, KOKO",
        "OTHNIEL UNIT, OGHARA",
        "PINNACLE UNIT, WARRI",
        "PRESSCO RED OIL",
        "PRUDENT UNIT",
        "RAIN OIL, OGHARA",
        "TAURUS UNIT, KOKO",
        "TOTAL UNIT, KOKO"
      ]
    },
    {
      depots: [
        "A&E UNIT, WARRI",
        "AIDA UNIT, WARRI",
        "BLUEFIN UNIT, WARRI",
        "CYBERNETICS, OGHARA",
        "EVERGREEN FIELD ENERGY",
        "HYDROCARBON",
        "KEONAMEX UNIT, WARRI",
        "I.N.T TOWER, KOKO, WARRI",
        "LANAKA UNIT/A.Y.M SHAFA",
        "MATRIX LPG GAS, WARRI",
        "MATRIX UNIT",
        "N.N.P.C BENIN UNIT",
        "N.N.P.C ORE",
        "N.N.P.C WARRI REFINERY",
        "NEPAL UNIT, OGHARA",
        "OGORODE SAPELE",
        "OKOMU RED OIL, BENIN",
        "OPTIMA UNIT, KOKO",
        "OTHNIEL UNIT, OGHARA",
        "PINNACLE UNIT, WARRI",
        "PRESSCO RED OIL",
        "PRUDENT UNIT",
        "RAIN OIL, OGHARA",
        "RIG-MOVE",
        "TAURUS UNIT, KOKO",
        "TOTAL UNIT, KOKO",
        "WATERSMITH",
      ]
    }
  ],

  port_harcourt_zones: [
    {
      state: "Abia",
      lga: [
        "Aba North",
        "Aba South",
        "Arochukwu",
        "Bende",
        "Ikwuano",
        "Isiala-Ngwa North",
        "Isiala-Ngwa South",
        "Isuikwato",
        "Obi Nwa",
        "Ohafia",
        "Osisioma",
        "Ngwa",
        "Ugwunagbo",
        "Ukwa East",
        "Ukwa West",
        "Umuahia North",
        "Umuahia South",
        "Umu-Neochi"
      ],
      units: [
        "ALKANES UNIT, CALABAR",
        "ASCA BITUMEN PLANT, PHC",
        "AZMAN UNIT, CALABAR",
        "BLOKSS ENERGY, CALABAR",
        "CIAT RAISING PALM OIL, PHC",
        "CONOIL PHC",
        "DOZZY (1) HIDE TANK, CALABAR",
        "AMASCO/DOZZY (2) CALABAR",
        "DOZZY (3)/BULK UNIT, PHC",
        "DOZZY (5)/UGOANA UNIT CALABAR",
        "EVA OIL UNIT, PHC",
        "FRESH ENERGY",
        "FYNEFIELD, CALABAR",
        "IBAFON UNIT, CALABAR",
        "KNIGHT UNIT",
        "LIQUID BULK PHC",
        "MAINLAND UNIT CALABAR",
        "MASTER ENERGY, PHC",
        "N.N.P.C ABA",
        "N.N.P.C CALABAR",
        "N.N.P.C ENUGU",
        "N.N.P.C MAKURDI",
        "N.N.P.C PHC REFINERY",
        "NIGER-DELTA REFINERY, PHC",
        "NORTHWEST UNIT, CALABAR",
        "OVH (2)/TSL, PHC",
        "ORYX UNIT",
        "PUMA LPG GAS, CALABAR",
        "P-Z/UNILIVER, ABA",
        "ROSAMYSTICAL UNIT, CALABAR",
        "SAIMON UNIT, CALABAR",
        "SHORELINK UNIT, PHC",
        "SIGMOND UNIT, PHC",
        "STOCK GAP, PHC",
        "TONEYMAS UNIT, PHC",
        "VIRGIN FOREST, ORON",
        "WABECO BITUMEN CALABAR",
        "Y.S.G UNIT, CALABAR"
      ]
    },
    {
      state: "AkwaIbom",
      lga: [
        "Abak",
        "Eastern Obolo",
        "Eket",
        "Esit Eket",
        "Essien Udim",
        "Etim Ekpo",
        "Etinan",
        "Ibeno",
        "Ibesikpo Asutan",
        "Ibiono Ibom",
        "Ika",
        "Ikono",
        "Ikot Abasi",
        "Ikot Ekpene",
        "Ini",
        "Itu",
        "Mbo",
        "Mkpat Enin",
        "Nsit Atai",
        "Nsit Ibom",
        "Nsit Ubium",
        "Obot Akara",
        "Okobo",
        "Onna",
        "Oron",
        "Oruk Anam",
        "Udung Uko",
        "Ukanafun",
        "Uruan",
        "Urue-Offong/Oruko ",
        "Uyo"
      ],
      units: [
        "ALKANES UNIT, CALABAR",
        "ASCA BITUMEN PLANT, PHC",
        "AZMAN UNIT, CALABAR",
        "BLOKSS ENERGY, CALABAR",
        "CIAT RAISING PALM OIL, PHC",
        "CONOIL PHC",
        "DOZZY (1) HIDE TANK, CALABAR",
        "AMASCO/DOZZY (2) CALABAR",
        "DOZZY (3)/BULK UNIT, PHC",
        "DOZZY (5)/UGOANA UNIT CALABAR",
        "EVA OIL UNIT, PHC",
        "FRESH ENERGY",
        "FYNEFIELD, CALABAR",
        "IBAFON UNIT, CALABAR",
        "KNIGHT UNIT",
        "LIQUID BULK PHC",
        "MAINLAND UNIT CALABAR",
        "MASTER ENERGY, PHC",
        "N.N.P.C ABA",
        "N.N.P.C CALABAR",
        "N.N.P.C ENUGU",
        "N.N.P.C MAKURDI",
        "N.N.P.C PHC REFINERY",
        "NIGER-DELTA REFINERY, PHC",
        "NORTHWEST UNIT, CALABAR",
        "OVH (2)/TSL, PHC",
        "ORYX UNIT",
        "PUMA LPG GAS, CALABAR",
        "P-Z/UNILIVER, ABA",
        "ROSAMYSTICAL UNIT, CALABAR",
        "SAIMON UNIT, CALABAR",
        "SHORELINK UNIT, PHC",
        "SIGMOND UNIT, PHC",
        "STOCK GAP, PHC",
        "TONEYMAS UNIT, PHC",
        "VIRGIN FOREST, ORON",
        "WABECO BITUMEN CALABAR",
        "Y.S.G UNIT, CALABAR"
      ]
    },
    {
      state: "Anambra",
      lga: [
        "Aguata",
        "Anambra East",
        "Anambra West",
        "Anaocha",
        "Awka North",
        "Awka South",
        "Ayamelum",
        "Dunukofia",
        "Ekwusigo",
        "Idemili North",
        "Idemili south",
        "Ihiala",
        "Njikoka",
        "Nnewi North",
        "Nnewi South",
        "Ogbaru",
        "Onitsha North",
        "Onitsha South",
        "Orumba North",
        "Orumba South",
        "Oyi"
      ],
      units: [
        "ALKANES UNIT, CALABAR",
        "ASCA BITUMEN PLANT, PHC",
        "AZMAN UNIT, CALABAR",
        "BLOKSS ENERGY, CALABAR",
        "CIAT RAISING PALM OIL, PHC",
        "CONOIL PHC",
        "DOZZY (1) HIDE TANK, CALABAR",
        "AMASCO/DOZZY (2) CALABAR",
        "DOZZY (3)/BULK UNIT, PHC",
        "DOZZY (5)/UGOANA UNIT CALABAR",
        "EVA OIL UNIT, PHC",
        "FRESH ENERGY",
        "FYNEFIELD, CALABAR",
        "IBAFON UNIT, CALABAR",
        "KNIGHT UNIT",
        "LIQUID BULK PHC",
        "MAINLAND UNIT CALABAR",
        "MASTER ENERGY, PHC",
        "N.N.P.C ABA",
        "N.N.P.C CALABAR",
        "N.N.P.C ENUGU",
        "N.N.P.C MAKURDI",
        "N.N.P.C PHC REFINERY",
        "NIGER-DELTA REFINERY, PHC",
        "NORTHWEST UNIT, CALABAR",
        "OVH (2)/TSL, PHC",
        "ORYX UNIT",
        "PUMA LPG GAS, CALABAR",
        "P-Z/UNILIVER, ABA",
        "ROSAMYSTICAL UNIT, CALABAR",
        "SAIMON UNIT, CALABAR",
        "SHORELINK UNIT, PHC",
        "SIGMOND UNIT, PHC",
        "STOCK GAP, PHC",
        "TONEYMAS UNIT, PHC",
        "VIRGIN FOREST, ORON",
        "WABECO BITUMEN CALABAR",
        "Y.S.G UNIT, CALABAR"
      ]
    },
    {
      state: "Bayelsa",
      lga: [
        "Brass",
        "Ekeremor",
        "Kolokuma/Opokuma",
        "Nembe",
        "Ogbia",
        "Sagbama",
        "Southern Jaw",
        "Yenegoa"
      ],
      units: [
        "ALKANES UNIT, CALABAR",
        "ASCA BITUMEN PLANT, PHC",
        "AZMAN UNIT, CALABAR",
        "BLOKSS ENERGY, CALABAR",
        "CIAT RAISING PALM OIL, PHC",
        "CONOIL PHC",
        "DOZZY (1) HIDE TANK, CALABAR",
        "AMASCO/DOZZY (2) CALABAR",
        "DOZZY (3)/BULK UNIT, PHC",
        "DOZZY (5)/UGOANA UNIT CALABAR",
        "EVA OIL UNIT, PHC",
        "FRESH ENERGY",
        "FYNEFIELD, CALABAR",
        "IBAFON UNIT, CALABAR",
        "KNIGHT UNIT",
        "LIQUID BULK PHC",
        "MAINLAND UNIT CALABAR",
        "MASTER ENERGY, PHC",
        "N.N.P.C ABA",
        "N.N.P.C CALABAR",
        "N.N.P.C ENUGU",
        "N.N.P.C MAKURDI",
        "N.N.P.C PHC REFINERY",
        "NIGER-DELTA REFINERY, PHC",
        "NORTHWEST UNIT, CALABAR",
        "OVH (2)/TSL, PHC",
        "ORYX UNIT",
        "PUMA LPG GAS, CALABAR",
        "P-Z/UNILIVER, ABA",
        "ROSAMYSTICAL UNIT, CALABAR",
        "SAIMON UNIT, CALABAR",
        "SHORELINK UNIT, PHC",
        "SIGMOND UNIT, PHC",
        "STOCK GAP, PHC",
        "TONEYMAS UNIT, PHC",
        "VIRGIN FOREST, ORON",
        "WABECO BITUMEN CALABAR",
        "Y.S.G UNIT, CALABAR"
      ]
    },
    {
      state: "Benue",
      lga: [
        "Ado",
        "Agatu",
        "Apa",
        "Buruku",
        "Gboko",
        "Guma",
        "Gwer East",
        "Gwer West",
        "Katsina-Ala",
        "Konshisha",
        "Kwande",
        "Logo",
        "Makurdi",
        "Obi",
        "Ogbadibo",
        "Oju",
        "Okpokwu",
        "Ohimini",
        "Oturkpo",
        "Tarka",
        "Ukum",
        "Ushongo",
        "Vandeikya"
      ],
      units: [
        "ALKANES UNIT, CALABAR",
        "ASCA BITUMEN PLANT, PHC",
        "AZMAN UNIT, CALABAR",
        "BLOKSS ENERGY, CALABAR",
        "CIAT RAISING PALM OIL, PHC",
        "CONOIL PHC",
        "DOZZY (1) HIDE TANK, CALABAR",
        "AMASCO/DOZZY (2) CALABAR",
        "DOZZY (3)/BULK UNIT, PHC",
        "DOZZY (5)/UGOANA UNIT CALABAR",
        "EVA OIL UNIT, PHC",
        "FRESH ENERGY",
        "FYNEFIELD, CALABAR",
        "IBAFON UNIT, CALABAR",
        "KNIGHT UNIT",
        "LIQUID BULK PHC",
        "MAINLAND UNIT CALABAR",
        "MASTER ENERGY, PHC",
        "N.N.P.C ABA",
        "N.N.P.C CALABAR",
        "N.N.P.C ENUGU",
        "N.N.P.C MAKURDI",
        "N.N.P.C PHC REFINERY",
        "NIGER-DELTA REFINERY, PHC",
        "NORTHWEST UNIT, CALABAR",
        "OVH (2)/TSL, PHC",
        "ORYX UNIT",
        "PUMA LPG GAS, CALABAR",
        "P-Z/UNILIVER, ABA",
        "ROSAMYSTICAL UNIT, CALABAR",
        "SAIMON UNIT, CALABAR",
        "SHORELINK UNIT, PHC",
        "SIGMOND UNIT, PHC",
        "STOCK GAP, PHC",
        "TONEYMAS UNIT, PHC",
        "VIRGIN FOREST, ORON",
        "WABECO BITUMEN CALABAR",
        "Y.S.G UNIT, CALABAR"
      ]
    },
    {
      state: "CrossRiver",
      lga: [
        "Akpabuyo",
        "Odukpani",
        "Akamkpa",
        "Biase",
        "Abi",
        "Ikom",
        "Yarkur",
        "Odubra",
        "Boki",
        "Ogoja",
        "Yala",
        "Obanliku",
        "Obudu",
        "Calabar South",
        "Etung",
        "Bekwara",
        "Bakassi",
        "Calabar Municipality"
      ],
      units: [
        "ALKANES UNIT, CALABAR",
        "ASCA BITUMEN PLANT, PHC",
        "AZMAN UNIT, CALABAR",
        "BLOKSS ENERGY, CALABAR",
        "CIAT RAISING PALM OIL, PHC",
        "CONOIL PHC",
        "DOZZY (1) HIDE TANK, CALABAR",
        "AMASCO/DOZZY (2) CALABAR",
        "DOZZY (3)/BULK UNIT, PHC",
        "DOZZY (5)/UGOANA UNIT CALABAR",
        "EVA OIL UNIT, PHC",
        "FRESH ENERGY",
        "FYNEFIELD, CALABAR",
        "IBAFON UNIT, CALABAR",
        "KNIGHT UNIT",
        "LIQUID BULK PHC",
        "MAINLAND UNIT CALABAR",
        "MASTER ENERGY, PHC",
        "N.N.P.C ABA",
        "N.N.P.C CALABAR",
        "N.N.P.C ENUGU",
        "N.N.P.C MAKURDI",
        "N.N.P.C PHC REFINERY",
        "NIGER-DELTA REFINERY, PHC",
        "NORTHWEST UNIT, CALABAR",
        "OVH (2)/TSL, PHC",
        "ORYX UNIT",
        "PUMA LPG GAS, CALABAR",
        "P-Z/UNILIVER, ABA",
        "ROSAMYSTICAL UNIT, CALABAR",
        "SAIMON UNIT, CALABAR",
        "SHORELINK UNIT, PHC",
        "SIGMOND UNIT, PHC",
        "STOCK GAP, PHC",
        "TONEYMAS UNIT, PHC",
        "VIRGIN FOREST, ORON",
        "WABECO BITUMEN CALABAR",
        "Y.S.G UNIT, CALABAR"
      ]

    },
    {
      state: "Enugu",
      lga: [
        "Enugu South,",
        "Igbo-Eze South",
        "Enugu North",
        "Nkanu",
        "Udi Agwu",
        "Oji-River",
        "Ezeagu",
        "IgboEze North",
        "Isi-Uzo",
        "Nsukka",
        "Igbo-Ekiti",
        "Uzo-Uwani",
        "Enugu Eas",
        "Aninri",
        "Nkanu East",
        "Udenu."
      ],
      units: [
        "ALKANES UNIT, CALABAR",
        "ASCA BITUMEN PLANT, PHC",
        "AZMAN UNIT, CALABAR",
        "BLOKSS ENERGY, CALABAR",
        "CIAT RAISING PALM OIL, PHC",
        "CONOIL PHC",
        "DOZZY (1) HIDE TANK, CALABAR",
        "AMASCO/DOZZY (2) CALABAR",
        "DOZZY (3)/BULK UNIT, PHC",
        "DOZZY (5)/UGOANA UNIT CALABAR",
        "EVA OIL UNIT, PHC",
        "FRESH ENERGY",
        "FYNEFIELD, CALABAR",
        "IBAFON UNIT, CALABAR",
        "KNIGHT UNIT",
        "LIQUID BULK PHC",
        "MAINLAND UNIT CALABAR",
        "MASTER ENERGY, PHC",
        "N.N.P.C ABA",
        "N.N.P.C CALABAR",
        "N.N.P.C ENUGU",
        "N.N.P.C MAKURDI",
        "N.N.P.C PHC REFINERY",
        "NIGER-DELTA REFINERY, PHC",
        "NORTHWEST UNIT, CALABAR",
        "OVH (2)/TSL, PHC",
        "ORYX UNIT",
        "PUMA LPG GAS, CALABAR",
        "P-Z/UNILIVER, ABA",
        "ROSAMYSTICAL UNIT, CALABAR",
        "SAIMON UNIT, CALABAR",
        "SHORELINK UNIT, PHC",
        "SIGMOND UNIT, PHC",
        "STOCK GAP, PHC",
        "TONEYMAS UNIT, PHC",
        "VIRGIN FOREST, ORON",
        "WABECO BITUMEN CALABAR",
        "Y.S.G UNIT, CALABAR"
      ]
    },
    {
      state: "Imo",
      lga: [
        "Aboh-Mbaise",
        "Ahiazu-Mbaise",
        "Ehime-Mbano",
        "Ezinihitte",
        "Ideato North",
        "Ideato South",
        "Ihitte/Uboma",
        "Ikeduru",
        "Isiala Mbano",
        "Isu",
        "Mbaitoli",
        "Mbaitoli",
        "Ngor-Okpala",
        "Njaba",
        "Nwangele",
        "Nkwerre",
        "Obowo",
        "Oguta",
        "Ohaji/Egbema",
        "Okigwe",
        "Orlu",
        "Orsu",
        "Oru East",
        "Oru West",
        "Owerri-Municipal",
        "Owerri North",
        "Owerri West"
      ],
      units: [
        "ALKANES UNIT, CALABAR",
        "ASCA BITUMEN PLANT, PHC",
        "AZMAN UNIT, CALABAR",
        "BLOKSS ENERGY, CALABAR",
        "CIAT RAISING PALM OIL, PHC",
        "CONOIL PHC",
        "DOZZY (1) HIDE TANK, CALABAR",
        "AMASCO/DOZZY (2) CALABAR",
        "DOZZY (3)/BULK UNIT, PHC",
        "DOZZY (5)/UGOANA UNIT CALABAR",
        "EVA OIL UNIT, PHC",
        "FRESH ENERGY",
        "FYNEFIELD, CALABAR",
        "IBAFON UNIT, CALABAR",
        "KNIGHT UNIT",
        "LIQUID BULK PHC",
        "MAINLAND UNIT CALABAR",
        "MASTER ENERGY, PHC",
        "N.N.P.C ABA",
        "N.N.P.C CALABAR",
        "N.N.P.C ENUGU",
        "N.N.P.C MAKURDI",
        "N.N.P.C PHC REFINERY",
        "NIGER-DELTA REFINERY, PHC",
        "NORTHWEST UNIT, CALABAR",
        "OVH (2)/TSL, PHC",
        "ORYX UNIT",
        "PUMA LPG GAS, CALABAR",
        "P-Z/UNILIVER, ABA",
        "ROSAMYSTICAL UNIT, CALABAR",
        "SAIMON UNIT, CALABAR",
        "SHORELINK UNIT, PHC",
        "SIGMOND UNIT, PHC",
        "STOCK GAP, PHC",
        "TONEYMAS UNIT, PHC",
        "VIRGIN FOREST, ORON",
        "WABECO BITUMEN CALABAR",
        "Y.S.G UNIT, CALABAR"
      ]
    },
    {
      state: "Rivers",
      lga: [
        "Abua/Odual",
        "Ahoada East",
        "Ahoada West",
        "Akuku Toru",
        "Andoni",
        "Asari-Toru",
        "Bonny",
        "Degema",
        "Emohua",
        "Eleme",
        "Etche",
        "Gokana",
        "Ikwerre",
        "Khana",
        "Obia/Akpor",
        "Ogba/Egbema/Ndoni",
        "Ogu/Bolo",
        "Okrika",
        "Omumma",
        "Opobo/Nkoro",
        "Oyigbo",
        "Port-Harcourt",
        "Tai"
      ],
      units: [
        "ALKANES UNIT, CALABAR",
        "ASCA BITUMEN PLANT, PHC",
        "AZMAN UNIT, CALABAR",
        "BLOKSS ENERGY, CALABAR",
        "CIAT RAISING PALM OIL, PHC",
        "CONOIL PHC",
        "DOZZY (1) HIDE TANK, CALABAR",
        "AMASCO/DOZZY (2) CALABAR",
        "DOZZY (3)/BULK UNIT, PHC",
        "DOZZY (5)/UGOANA UNIT CALABAR",
        "EVA OIL UNIT, PHC",
        "FRESH ENERGY",
        "FYNEFIELD, CALABAR",
        "IBAFON UNIT, CALABAR",
        "KNIGHT UNIT",
        "LIQUID BULK PHC",
        "MAINLAND UNIT CALABAR",
        "MASTER ENERGY, PHC",
        "N.N.P.C ABA",
        "N.N.P.C CALABAR",
        "N.N.P.C ENUGU",
        "N.N.P.C MAKURDI",
        "N.N.P.C PHC REFINERY",
        "NIGER-DELTA REFINERY, PHC",
        "NORTHWEST UNIT, CALABAR",
        "OVH (2)/TSL, PHC",
        "ORYX UNIT",
        "PUMA LPG GAS, CALABAR",
        "P-Z/UNILIVER, ABA",
        "ROSAMYSTICAL UNIT, CALABAR",
        "SAIMON UNIT, CALABAR",
        "SHORELINK UNIT, PHC",
        "SIGMOND UNIT, PHC",
        "STOCK GAP, PHC",
        "TONEYMAS UNIT, PHC",
        "VIRGIN FOREST, ORON",
        "WABECO BITUMEN CALABAR",
        "Y.S.G UNIT, CALABAR"
      ]
    },
    {
      depots: [
        "ALKANES UNIT, CALABAR",
        "ASCA BITUMEN PLANT, PHC",
        "AZMAN UNIT, CALABAR",
        "BLOKSS ENERGY, CALABAR",
        "CIAT RAISING PALM OIL, PHC",
        "CONOIL PHC",
        "DOZZY (1) HIDE TANK, CALABAR",
        "AMASCO/DOZZY (2) CALABAR",
        "DOZZY (3)/BULK UNIT, PHC",
        "DOZZY (5)/UGOANA UNIT CALABAR",
        "EVA OIL UNIT, PHC",
        "EVERGREEN FIELD ENERGY",
        "FRESH ENERGY",
        "FYNEFIELD, CALABAR",
        "HYDROCARBON",
        "IBAFON UNIT, CALABAR",
        "KNIGHT UNIT",
        "LIQUID BULK PHC",
        "MAINLAND UNIT CALABAR",
        "MASTER ENERGY, PHC",
        "N.N.P.C ABA",
        "N.N.P.C CALABAR",
        "N.N.P.C ENUGU",
        "N.N.P.C MAKURDI",
        "N.N.P.C PHC REFINERY",
        "NIGER-DELTA REFINERY, PHC",
        "NORTHWEST UNIT, CALABAR",
        "OVH (2)/TSL, PHC",
        "ORYX UNIT",
        "PUMA LPG GAS, CALABAR",
        "P-Z/UNILIVER, ABA",
        "RIG-MOVE",
        "ROSAMYSTICAL UNIT, CALABAR",
        "SAIMON UNIT, CALABAR",
        "SHORELINK UNIT, PHC",
        "SIGMOND UNIT, PHC",
        "STOCK GAP, PHC",
        "SPOG",
        "TONEYMAS UNIT, PHC",
        "VIRGIN FOREST, ORON",
        "WABECO BITUMEN CALABAR",
        "WATERSMITH",
        "Y.S.G UNIT, CALABAR",
      ]
    }
  ],

  lagos_states: [
    {
      state: "Ekiti",
      lga: [
        "Ado",
        "Ekiti-East",
        "Ekiti-West",
        "Emure/Ise/Orun",
        "Ekiti South-West",
        "Ikare",
        "Irepodun",
        "Ijero,",
        "Ido/Osi",
        "Oye",
        "Ikole",
        "Moba",
        "Gbonyin",
        "Efon",
        "Ise/Orun",
        "Ilejemeje"
      ],
      units: [
        "A.A RANO",
        "AFRICAN TERMINAL/ACORN UNIT",
        "AIPECK/DEEP WATER",
        "AITEO",
        "AP/FORTE OIL",
        "ASCON UNIT",
        "AVIATION UNIT",
        "A-Z UNIT",
        "BOND UNIT",
        "BOVAS UNIT",
        "CHIPET UNIT",
        "CHISCO UNIT",
        "CONOIL",
        "DEE JONES UNIT",
        "DOCKYARD UNIT",
        "EMADEB UNIT",
        "ETERNA OIL",
        "FATGBEMS UNIT",
        "FIRST ROYAL UNIT",
        "FOLAWIYO UNIT",
        "GULF TREASURE UNIT",
        "HEYDEN UNIT",
        "HONEYWELL",
        "TOTAL UNIT",
        "IBACHEM UNIT",
        "IBETO UNIT",
        "IMAD/ BOVAS 2",
        "INDEX UNIT",
        "INTEGRATED UNIT",
        "LUBRICANT UNIT",
        "M. A . O UNIT",
        "MENJI UNIT",
        "MOBIL OIL UNIT",
        "MRS UNIT, TINCAN",
        "NNPC APATA, IBADAN",
        "NNPC EJIGBO",
        "NNPC ILORIN",
        "NNPC MOSIMI",
        "NAFGAS LPG UNIT",
        "NIPCO UNIT",
        "NNPC LPG GAS",
        "NPA UNIT",
        "OVH  (1)",
        "OVH  (2)",
        "OBAT OIL",
        "PUMA BITUMEN",
        "PZ/ UNILEVER",
        "RAHAMANIYYA UNIT",
        "RAINOIL UNIT",
        "SAHARA UNIT",
        "SWIFT OIL UNIT",
        "TECHNO OIL",
        "TELLERVERAS/ LENGHTON UNIT",
        "TEXACO/ MRS UNIT",
        "TOTAL (1)",
        "TOTAL (2)",
        "TOTAL (3) / BONO ENERGY",
        "WOSBAB UNIT",
        "BOVAS 2",
        "T. TIME UNIT"
      ]
    },
    {
      state: "Kwara",
      lga: [
        "Asa",
        "Baruten",
        "Edu",
        "Ekiti",
        "Ifelodun",
        "Ilorin East",
        "Ilorin West",
        "Irepodun",
        "Isin",
        "Kaiama",
        "Moro",
        "Offa",
        "Oke-Ero",
        "Oyun",
        "Pategi"
      ],
      units: [
        "A.A RANO",
        "AFRICAN TERMINAL/ACORN UNIT",
        "AIPECK/DEEP WATER",
        "AITEO",
        "AP/FORTE OIL",
        "ASCON UNIT",
        "AVIATION UNIT",
        "A-Z UNIT",
        "BOND UNIT",
        "BOVAS UNIT",
        "CHIPET UNIT",
        "CHISCO UNIT",
        "CONOIL",
        "DEE JONES UNIT",
        "DOCKYARD UNIT",
        "EMADEB UNIT",
        "ETERNA OIL",
        "FATGBEMS UNIT",
        "FIRST ROYAL UNIT",
        "FOLAWIYO UNIT",
        "GULF TREASURE UNIT",
        "HEYDEN UNIT",
        "HONEYWELL",
        "TOTAL UNIT",
        "IBACHEM UNIT",
        "IBETO UNIT",
        "IMAD/ BOVAS 2",
        "INDEX UNIT",
        "INTEGRATED UNIT",
        "LUBRICANT UNIT",
        "M. A . O UNIT",
        "MENJI UNIT",
        "MOBIL OIL UNIT",
        "MRS UNIT, TINCAN",
        "NNPC APATA, IBADAN",
        "NNPC EJIGBO",
        "NNPC ILORIN",
        "NNPC MOSIMI",
        "NAFGAS LPG UNIT",
        "NIPCO UNIT",
        "NNPC LPG GAS",
        "NPA UNIT",
        "OVH  (1)",
        "OVH  (2)",
        "OBAT OIL",
        "PUMA BITUMEN",
        "PZ/ UNILEVER",
        "RAHAMANIYYA UNIT",
        "RAINOIL UNIT",
        "SAHARA UNIT",
        "SWIFT OIL UNIT",
        "TECHNO OIL",
        "TELLERVERAS/ LENGHTON UNIT",
        "TEXACO/ MRS UNIT",
        "TOTAL (1)",
        "TOTAL (2)",
        "TOTAL (3) / BONO ENERGY",
        "WOSBAB UNIT",
        "BOVAS 2",
        "T. TIME UNIT"
      ]
    },
    {
      state: "Lagos",
      lga: [
        "Agege",
        "Ajeromi-Ifelodun",
        "Alimosho",
        "Amuwo-Odofin",
        "Apapa",
        "Badagry",
        "Epe",
        "Eti-Osa",
        "Ibeju/Lekki",
        "Ifako-Ijaye",
        "Ikeja",
        "Ikorodu",
        "Kosofe",
        "Lagos Island",
        "Lagos Mainland",
        "Mushin",
        "Ojo",
        "Oshodi-Isolo",
        "Shomolu",
        "Surulere"
      ],
      units: [
        "A.A RANO",
        "AFRICAN TERMINAL/ACORN UNIT",
        "AIPECK/DEEP WATER",
        "AITEO",
        "AP/FORTE OIL",
        "ASCON UNIT",
        "AVIATION UNIT",
        "A-Z UNIT",
        "BOND UNIT",
        "BOVAS UNIT",
        "CHIPET UNIT",
        "CHISCO UNIT",
        "CONOIL",
        "DEE JONES UNIT",
        "DOCKYARD UNIT",
        "EMADEB UNIT",
        "ETERNA OIL",
        "FATGBEMS UNIT",
        "FIRST ROYAL UNIT",
        "FOLAWIYO UNIT",
        "GULF TREASURE UNIT",
        "HEYDEN UNIT",
        "HONEYWELL",
        "TOTAL UNIT",
        "IBACHEM UNIT",
        "IBETO UNIT",
        "IMAD/ BOVAS 2",
        "INDEX UNIT",
        "INTEGRATED UNIT",
        "LUBRICANT UNIT",
        "M. A . O UNIT",
        "MENJI UNIT",
        "MOBIL OIL UNIT",
        "MRS UNIT, TINCAN",
        "NNPC APATA, IBADAN",
        "NNPC EJIGBO",
        "NNPC ILORIN",
        "NNPC MOSIMI",
        "NAFGAS LPG UNIT",
        "NIPCO UNIT",
        "NNPC LPG GAS",
        "NPA UNIT",
        "OVH  (1)",
        "OVH  (2)",
        "OBAT OIL",
        "PUMA BITUMEN",
        "PZ/ UNILEVER",
        "RAHAMANIYYA UNIT",
        "RAINOIL UNIT",
        "SAHARA UNIT",
        "SWIFT OIL UNIT",
        "TECHNO OIL",
        "TELLERVERAS/ LENGHTON UNIT",
        "TEXACO/ MRS UNIT",
        "TOTAL (1)",
        "TOTAL (2)",
        "TOTAL (3) / BONO ENERGY",
        "WOSBAB UNIT",
        "BOVAS 2",
        "T. TIME UNIT"
      ]
    },
    {
      state: "Ogun",
      lga: [
        "Abeokuta North",
        "Abeokuta South",
        "Ado-Odo/Ota",
        "Egbado North",
        "Egbado South",
        "Ewekoro",
        "Ifo",
        "Ijebu East",
        "Ijebu North",
        "Ijebu North East",
        "Ijebu Ode",
        "Ikenne",
        "Imeko-Afon",
        "Ipokia",
        "Obafemi-Owode",
        "Ogun Waterside",
        "Odeda",
        "Odogbolu",
        "Remo North",
        "Shagamu"
      ],
      units: [
        "A.A RANO",
        "AFRICAN TERMINAL/ACORN UNIT",
        "AIPECK/DEEP WATER",
        "AITEO",
        "AP/FORTE OIL",
        "ASCON UNIT",
        "AVIATION UNIT",
        "A-Z UNIT",
        "BOND UNIT",
        "BOVAS UNIT",
        "CHIPET UNIT",
        "CHISCO UNIT",
        "CONOIL",
        "DEE JONES UNIT",
        "DOCKYARD UNIT",
        "EMADEB UNIT",
        "ETERNA OIL",
        "FATGBEMS UNIT",
        "FIRST ROYAL UNIT",
        "FOLAWIYO UNIT",
        "GULF TREASURE UNIT",
        "HEYDEN UNIT",
        "HONEYWELL",
        "TOTAL UNIT",
        "IBACHEM UNIT",
        "IBETO UNIT",
        "IMAD/ BOVAS 2",
        "INDEX UNIT",
        "INTEGRATED UNIT",
        "LUBRICANT UNIT",
        "M. A . O UNIT",
        "MENJI UNIT",
        "MOBIL OIL UNIT",
        "MRS UNIT, TINCAN",
        "NNPC APATA, IBADAN",
        "NNPC EJIGBO",
        "NNPC ILORIN",
        "NNPC MOSIMI",
        "NAFGAS LPG UNIT",
        "NIPCO UNIT",
        "NNPC LPG GAS",
        "NPA UNIT",
        "OVH  (1)",
        "OVH  (2)",
        "OBAT OIL",
        "PUMA BITUMEN",
        "PZ/ UNILEVER",
        "RAHAMANIYYA UNIT",
        "RAINOIL UNIT",
        "SAHARA UNIT",
        "SWIFT OIL UNIT",
        "TECHNO OIL",
        "TELLERVERAS/ LENGHTON UNIT",
        "TEXACO/ MRS UNIT",
        "TOTAL (1)",
        "TOTAL (2)",
        "TOTAL (3) / BONO ENERGY",
        "WOSBAB UNIT",
        "BOVAS 2",
        "T. TIME UNIT"
      ]
    },
    {
      state: "Ondo",
      lga: [
        "Akoko North East",
        "Akoko North West",
        "Akoko South Akure East",
        "Akoko South West",
        "Akure North",
        "Akure South",
        "Ese-Odo",
        "Idanre",
        "Ifedore",
        "Ilaje",
        "Ile-Oluji",
        "Okeigbo",
        "Irele",
        "Odigbo",
        "Okitipupa",
        "Ondo East",
        "Ondo West",
        "Ose",
        "Owo"
      ],
      units: [
        "A.A RANO",
        "AFRICAN TERMINAL/ACORN UNIT",
        "AIPECK/DEEP WATER",
        "AITEO",
        "AP/FORTE OIL",
        "ASCON UNIT",
        "AVIATION UNIT",
        "A-Z UNIT",
        "BOND UNIT",
        "BOVAS UNIT",
        "CHIPET UNIT",
        "CHISCO UNIT",
        "CONOIL",
        "DEE JONES UNIT",
        "DOCKYARD UNIT",
        "EMADEB UNIT",
        "ETERNA OIL",
        "FATGBEMS UNIT",
        "FIRST ROYAL UNIT",
        "FOLAWIYO UNIT",
        "GULF TREASURE UNIT",
        "HEYDEN UNIT",
        "HONEYWELL",
        "TOTAL UNIT",
        "IBACHEM UNIT",
        "IBETO UNIT",
        "IMAD/ BOVAS 2",
        "INDEX UNIT",
        "INTEGRATED UNIT",
        "LUBRICANT UNIT",
        "M. A . O UNIT",
        "MENJI UNIT",
        "MOBIL OIL UNIT",
        "MRS UNIT, TINCAN",
        "NNPC APATA, IBADAN",
        "NNPC EJIGBO",
        "NNPC ILORIN",
        "NNPC MOSIMI",
        "NAFGAS LPG UNIT",
        "NIPCO UNIT",
        "NNPC LPG GAS",
        "NPA UNIT",
        "OVH  (1)",
        "OVH  (2)",
        "OBAT OIL",
        "PUMA BITUMEN",
        "PZ/ UNILEVER",
        "RAHAMANIYYA UNIT",
        "RAINOIL UNIT",
        "SAHARA UNIT",
        "SWIFT OIL UNIT",
        "TECHNO OIL",
        "TELLERVERAS/ LENGHTON UNIT",
        "TEXACO/ MRS UNIT",
        "TOTAL (1)",
        "TOTAL (2)",
        "TOTAL (3) / BONO ENERGY",
        "WOSBAB UNIT",
        "BOVAS 2",
        "T. TIME UNIT"
      ]
    },
    {
      state: "Osun",
      lga: [
        "Aiyedade",
        "Aiyedire",
        "Atakumosa East",
        "Atakumosa West",
        "Boluwaduro",
        "Boripe",
        "Ede North",
        "Ede South",
        "Egbedore",
        "Ejigbo",
        "Ife Central",
        "Ife East",
        "Ife North",
        "Ife South",
        "Ifedayo",
        "Ifelodun",
        "Ila",
        "Ilesha East",
        "Ilesha West",
        "Irepodun",
        "Irewole",
        "Isokan",
        "Iwo",
        "Obokun",
        "Odo-Otin",
        "Ola-Oluwa",
        "Olorunda",
        "Oriade",
        "Orolu",
        "Osogbo"
      ],
      units: [
        "A.A RANO",
        "AFRICAN TERMINAL/ACORN UNIT",
        "AIPECK/DEEP WATER",
        "AITEO",
        "AP/FORTE OIL",
        "ASCON UNIT",
        "AVIATION UNIT",
        "A-Z UNIT",
        "BOND UNIT",
        "BOVAS UNIT",
        "CHIPET UNIT",
        "CHISCO UNIT",
        "CONOIL",
        "DEE JONES UNIT",
        "DOCKYARD UNIT",
        "EMADEB UNIT",
        "ETERNA OIL",
        "FATGBEMS UNIT",
        "FIRST ROYAL UNIT",
        "FOLAWIYO UNIT",
        "GULF TREASURE UNIT",
        "HEYDEN UNIT",
        "HONEYWELL",
        "TOTAL UNIT",
        "IBACHEM UNIT",
        "IBETO UNIT",
        "IMAD/ BOVAS 2",
        "INDEX UNIT",
        "INTEGRATED UNIT",
        "LUBRICANT UNIT",
        "M. A . O UNIT",
        "MENJI UNIT",
        "MOBIL OIL UNIT",
        "MRS UNIT, TINCAN",
        "NNPC APATA, IBADAN",
        "NNPC EJIGBO",
        "NNPC ILORIN",
        "NNPC MOSIMI",
        "NAFGAS LPG UNIT",
        "NIPCO UNIT",
        "NNPC LPG GAS",
        "NPA UNIT",
        "OVH  (1)",
        "OVH  (2)",
        "OBAT OIL",
        "PUMA BITUMEN",
        "PZ/ UNILEVER",
        "RAHAMANIYYA UNIT",
        "RAINOIL UNIT",
        "SAHARA UNIT",
        "SWIFT OIL UNIT",
        "TECHNO OIL",
        "TELLERVERAS/ LENGHTON UNIT",
        "TEXACO/ MRS UNIT",
        "TOTAL (1)",
        "TOTAL (2)",
        "TOTAL (3) / BONO ENERGY",
        "WOSBAB UNIT",
        "BOVAS 2",
        "T. TIME UNIT"
      ]
    },
    {
      state: "Oyo",
      lga: [
        "Afijio",
        "Akinyele",
        "Atiba",
        "Atigbo",
        "Egbeda",
        "Ibadan Central",
        "Ibadan North",
        "Ibadan North West",
        "Ibadan South East",
        "Ibadan South West",
        "Ibarapa Central",
        "Ibarapa East",
        "Ibarapa North",
        "Ido",
        "Irepo",
        "Iseyin",
        "Itesiwaju",
        "Iwajowa",
        "Kajola",
        "Lagelu Ogbomosho North",
        "Ogbmosho South",
        "Ogo Oluwa",
        "Olorunsogo",
        "Oluyole",
        "Ona-Ara",
        "Orelope",
        "Ori Ire",
        "Oyo East",
        "Oyo West",
        "Saki East",
        "Saki West",
        "Surulere"
      ],
      units: [
        "A.A RANO",
        "AFRICAN TERMINAL/ACORN UNIT",
        "AIPECK/DEEP WATER",
        "AITEO",
        "AP/FORTE OIL",
        "ASCON UNIT",
        "AVIATION UNIT",
        "A-Z UNIT",
        "BOND UNIT",
        "BOVAS UNIT",
        "CHIPET UNIT",
        "CHISCO UNIT",
        "CONOIL",
        "DEE JONES UNIT",
        "DOCKYARD UNIT",
        "EMADEB UNIT",
        "ETERNA OIL",
        "FATGBEMS UNIT",
        "FIRST ROYAL UNIT",
        "FOLAWIYO UNIT",
        "GULF TREASURE UNIT",
        "HEYDEN UNIT",
        "HONEYWELL",
        "TOTAL UNIT",
        "IBACHEM UNIT",
        "IBETO UNIT",
        "IMAD/ BOVAS 2",
        "INDEX UNIT",
        "INTEGRATED UNIT",
        "LUBRICANT UNIT",
        "M. A . O UNIT",
        "MENJI UNIT",
        "MOBIL OIL UNIT",
        "MRS UNIT, TINCAN",
        "NNPC APATA, IBADAN",
        "NNPC EJIGBO",
        "NNPC ILORIN",
        "NNPC MOSIMI",
        "NAFGAS LPG UNIT",
        "NIPCO UNIT",
        "NNPC LPG GAS",
        "NPA UNIT",
        "OVH  (1)",
        "OVH  (2)",
        "OBAT OIL",
        "PUMA BITUMEN",
        "PZ/ UNILEVER",
        "RAHAMANIYYA UNIT",
        "RAINOIL UNIT",
        "SAHARA UNIT",
        "SWIFT OIL UNIT",
        "TECHNO OIL",
        "TELLERVERAS/ LENGHTON UNIT",
        "TEXACO/ MRS UNIT",
        "TOTAL (1)",
        "TOTAL (2)",
        "TOTAL (3) / BONO ENERGY",
        "WOSBAB UNIT",
        "BOVAS 2",
        "T. TIME UNIT"
      ]
    },
    {
      depots: [
        "A.A RANO",
        "AFRICAN TERMINAL/ACORN UNIT",
        "AIPECK/DEEP WATER",
        "AITEO",
        "AP/FORTE OIL",
        "ASCON UNIT",
        "AVIATION UNIT",
        "A-Z UNIT",
        "BOND UNIT",
        "BOVAS UNIT",
        "CHIPET UNIT",
        "CHISCO UNIT",
        "CONOIL",
        "DEE JONES UNIT",
        "DOCKYARD UNIT",
        "EMADEB UNIT",
        "ETERNA OIL",
        "EVERGREEN FIELD ENERGY",
        "FATGBEMS UNIT",
        "FIRST ROYAL UNIT",
        "FOLAWIYO UNIT",
        "GULF TREASURE UNIT",
        "HEYDEN UNIT",
        "HONEYWELL",
        "HYDROCARBON",
        "KATA OIL",
        "IBACHEM UNIT",
        "IBETO UNIT",
        "IMAD/BOVAS 2",
        "INDEX UNIT",
        "INTEGRATED UNIT",
        "LUBRICANT UNIT",
        "M. A . O UNIT",
        "MENJI UNIT",
        "MOBIL OIL UNIT",
        "MRS APAPA",
        "MRS UNIT, TINCAN",
        "NNPC APATA, IBADAN",
        "NNPC EJIGBO",
        "NNPC ILORIN",
        "NNPC MOSIMI",
        "NAFGAS LPG UNIT",
        "NIPCO UNIT",
        "NNPC LPG GAS ",
        "NPA UNIT",
        "OVH  (1)",
        "OVH  (2)",
        "OBAT OIL",
        "PUMA BITUMEN",
        "PZ/ UNILEVER",
        "PINNACLE",
        "RAHAMANIYYA UNIT",
        "RAINOIL UNIT",
        "RIG-MOVE",
        "SAHARA UNIT",
        "SHARFA ENERGY",
        "SPOG",
        "SWIFT OIL UNIT",
        "TECHNO OIL",
        "TELLERVERAS/ LENGHTON UNIT",
        "TEXACO/ MRS UNIT",
        "TOTAL UNIT",
        "TOTAL (1)",
        "TOTAL (2)",
        "TOTAL (3) / BONO ENERGY",
        "WATERSMITH",
        "WOSBAB UNIT",
        "BOVAS 2",
        "T. TIME UNIT",
      ]
    }
  ]
};

export const localGovernments = {

  Ebonyi: [
    "Afikpo South",
    "Afikpo North",
    "Onicha",
    "Ohaozara",
    "Abakaliki",
    "Ishielu",
    "lkwo",
    "Ezza",
    "Ezza South",
    "Ohaukwu",
    "Ebonyi",
    "Ivo"
  ],

};