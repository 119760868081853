<div class="container-scroller">
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center auth auth-bg-1 theme-one">
      <div class="row w-100">
        <div class="col-lg-4 mx-auto">
          <div class="auto-form-wrapper">
            <form [formGroup]="credentialsForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <label class="label">Email</label>
                <div class="input-group">
                  <input type="text" class="form-control" placeholder="Email" formControlName="email">
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i class="mdi mdi-check-circle-outline"></i>
                    </span>
                  </div>
                  <div *ngIf="!credentialsForm.controls.email.valid &&
            credentialsForm.controls.email.dirty"
            class="validator-error">
        <p>Enter a valid Email</p>
        </div>
                </div>
              </div>
              <div class="form-group">
                <label class="label">Password</label>
                <div class="input-group">
                  <input type="password" class="form-control" placeholder="*********" formControlName="password">
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i class="mdi mdi-check-circle-outline"></i>
                    </span>
                  </div>
                  <div *ngIf="!credentialsForm.controls.password.valid &&
            credentialsForm.controls.password.dirty"
            class="validator-error">
        <p>Enter a valid password</p>
        </div>
                </div>
              </div>
              <div class="form-group">
                <button [disabled]="!credentialsForm.valid" class="btn btn-dark submit-btn btn-block" [class.spinner]="loading" [disabled]="loading" type="submit">{{ title }}</button>
              </div>
              <div class="form-group d-flex justify-content-between">
                <div class="form-check form-check-flat mt-0">
                  <a routerLink="/agent/forgot-password" class="text-black">Forgot Password</a>
                </div>
                <!-- <a href="#" class="text-small forgot-password text-black">Forgot Password</a> -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>
  <!-- page-body-wrapper ends -->
</div>