import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { User } from '../shared/users.model';
import { BehaviorSubject, Subject, } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { ExcoAuthService } from '../auth/exco.auth.service';
import { tap } from 'rxjs/operators';
import { Agent } from '../shared/agent.model';
const BACKEND_URL = environment.apiUrl;
@Injectable({
    providedIn: "root"
})

export class AgentService {
    private users: User[] = [];
    private excoUsers: User[] = [];
    private userStatusListener = new BehaviorSubject<User[]>([]);
    usersChanged = new Subject<{ users: User[], totalUsers: number }>();
    excoUsersChanged = new Subject<{ users: User[], totalUsers: number }>();
    constructor(
        private http: HttpClient,
        private router: Router,
        private authService: AuthService,
        private excoAuthService: ExcoAuthService
    ) { }

    getUserStatusListener() {
        return this.userStatusListener.asObservable();
    }

    createUser(fingerId: string, user: User) {
        const postCredentials = {
            firstname: user.firstname,
            middlename: user.middlename,
            lastname: user.surname,
            email: user.email,
            gender: user.gender,
            dob: user.dob,
            zone: user.zone,
            unit: user.unit,
            depot: user.depot,
            phone_no: user.phone_no,
            state: user.state,
            signature: user.signature,
            vehicleNumber: user.vehicleNumber,
            verifiedId: user.verifiedId,
            verifiedIdType: user.verifiedIdType,
            transportation_type: user.transportation_type,
            next_of_kin_address: user.next_of_kin_address,
            next_of_kin_name: user.next_of_kin_name,
            next_of_kin_phone_no: user.next_of_kin_phone_no,
            branch: user.branch,
            address: user.address,
            image: user.image,
            uniqueId: user.uniqueId,
            membership_type: user.membership_type ?? "",
            drivers_licence:  user.drivers_licence ?? "",
            vehicle_license: user.vehicle_license ?? "",
            contact_number: user.contact_number ?? "",
            truck_owner_full_name: user.truck_owner_full_name ?? "",
            truck_owner_phone_no: user.truck_owner_phone_no ?? "",
            sponsored_by: user.sponsored_by ?? "",
            capture_location: user.capture_location ?? "",
        }
        const token = this.authService.getToken();
        return this.http.post<{ user: User }>(BACKEND_URL + 'agent/user/' + fingerId, user,
            {
                headers: new HttpHeaders({ Authorization: "Bearer " + token })
            }).pipe(tap(responseData => {
                this.users.push(responseData.user);
                this.userStatusListener.next(this.users);
            }));
    }

    updateExistingUsers(uniqueID: string, user: User) {
        
        const token = this.authService.getToken();
        return this.http.patch<{ message: string }>(BACKEND_URL + 'agent/user/update/old/uniqueid', user,
            {
                headers: new HttpHeaders({ Authorization: "Bearer " + token })
            });
    }

    getUsers(postSizePerPage: number, currentPage: number) {
        const token = this.authService.getToken();
        return this.http.get<{ users: User[], totalUsers: number }>(BACKEND_URL + 'agent/user',

            {
                headers: new HttpHeaders({ Authorization: "Bearer " + token }),
                params: new HttpParams().set('currentPage', currentPage.toString()).append('postSizeOptions', postSizePerPage.toString())
            }).pipe(tap(responseData => {
                this.users = responseData.users;
                this.userStatusListener.next(this.users);
                this.usersChanged.next({
                    users: this.users.slice(),
                    totalUsers: responseData.totalUsers,
                });
            }));
    }

    getExcoUsers(postSizePerPage: number, currentPage: number) {
        const token = this.excoAuthService.getToken();
        return this.http.get<{ users: User[], totalUsers: number }>(BACKEND_URL + 'agent/exco/users',

            {
                headers: new HttpHeaders({ ExcoAuthorization: "Bearer " + token }),
                params: new HttpParams().set('currentPage', currentPage.toString()).append('postSizeOptions', postSizePerPage.toString())
            })
            .pipe(tap(responseData => {
                this.excoUsers = responseData.users;
                const totalUsers = responseData.totalUsers;
                this.excoUsersChanged.next({
                    users: this.excoUsers.slice(),
                    totalUsers: totalUsers
                });
            }));
    }

    getAgents() {
        const token = this.excoAuthService.getToken();
        return this.http.get<{ agents: Agent[], totalUsers: number, totalAgents: number }>(BACKEND_URL + 'agent/exco-agents',
            {
                headers: new HttpHeaders({ ExcoAuthorization: "Bearer " + token })
            });
    }

    getAgentRegisteredUsers(agentId: string, postSizePerPage: number, currentPage: number) {
        const token = this.excoAuthService.getToken();
        return this.http.get<{ users: User[], totalUsers: number }>(BACKEND_URL + 'agent/exco/' + agentId + '/registered',
            {
                headers: new HttpHeaders({ ExcoAuthorization: "Bearer " + token }),
                params: new HttpParams().set('currentPage', currentPage.toString()).append('postSizeOptions', postSizePerPage.toString())
            });
    }

    getUserDetailById(uniqueId: string) {
        const token = this.excoAuthService.getToken();
        const postData = {
            uniqueId: uniqueId
        }
        return this.http.post<{ user: User }>(BACKEND_URL + 'agent/exco/user/uniqueId', postData,
            {
                headers: new HttpHeaders({ ExcoAuthorization: "Bearer " + token })
            });
    }

    getUserByFingerId(fingerId: string) {
        const token = this.authService.getToken();
        return this.http.get<{ user: User }>(BACKEND_URL + 'agent/user/finger/' + fingerId,

            {
                headers: new HttpHeaders({ Authorization: "Bearer " + token })
            })
    }

    getUserByUniqueId(uniqueId: string) {
        const token = this.authService.getToken();
        const postCredentials = {
            uniqueId: uniqueId
        }
        console.log(postCredentials);
        return this.http.post<{ user: User }>(BACKEND_URL + 'agent/user/update/old/uniqueid/', postCredentials,
            {
                headers: new HttpHeaders({ Authorization: "Bearer " + token })
            },)
    }
}