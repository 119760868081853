<body>
    <div class="container-scroller">
      <!-- partial:partials/_navbar.html -->
      <nav class="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
          <a class="navbar-brand brand-logo" href="#">
            <img src="assets/images/logo.png" height="80">
          </a>
        </div>
        <div class="navbar-menu-wrapper d-flex align-items-center">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item dropdown d-none d-xl-inline-block user-dropdown">
              <a class="nav-link dropdown-toggle" id="UserDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                <img class="img-xs rounded-circle" src="../assets/images/faces/face8.jpg" alt="Profile image"> </a>
              <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
                <div class="dropdown-header text-center">
                  <img class="img-md rounded-circle" src="../assets/images/faces/face8.jpg" alt="Profile image">
                  <p class="mb-1 mt-3 font-weight-semibold"></p>
                  <p class="font-weight-light text-muted mb-0">Admin</p>
                </div>
                <a class="dropdown-item" routerLink="/admin/profile">My Profile <i class="dropdown-item-icon ti-dashboard"></i></a>
                <a class="dropdown-item" style="cursor: pointer;" (click)="logout()">Sign Out<i class="dropdown-item-icon ti-power-off"></i></a>
              </div>
            </li>
          </ul>
          <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
            <span class="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
      <!-- partial -->
      <div class="container-fluid page-body-wrapper">
        <!-- partial:partials/_sidebar.html -->
        <nav class="sidebar sidebar-offcanvas" id="sidebar">
          <ul class="nav">
            <li class="nav-item nav-profile">
              <a href="#" class="nav-link">
                <div class="profile-image">
                  <img class="img-xs rounded-circle" src="../assets/images/faces/face8.jpg" alt="profile image">
                  <div class="dot-indicator bg-success"></div>
                </div>
                <div class="text-wrapper">
                  <p class="profile-name"></p>
                  <p class="designation">Admin</p>
                </div>
              </a>
            </li>
            <li class="nav-item nav-category">Main Menu</li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/admin/dashboard">
                <i class="menu-icon typcn typcn-document-text"></i>
                <span class="menu-title">Dashboard</span>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" routerLink="/admin/add-agent">
                <i class="menu-icon typcn typcn-user-add"></i>
                <span class="menu-title">Add Agent</span>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link active" routerLink="/admin/view-agent">
                <i class="menu-icon typcn typcn-eye"></i>
                <span class="menu-title">View Agents</span>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" routerLink="/admin/add-exco">
                <i class="menu-icon typcn typcn-user-outline"></i>
                <span class="menu-title">Add Exco</span>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" routerLink="/admin/excos">
                <i class="menu-icon typcn typcn-eye-outline"></i>
                <span class="menu-title">View Excos</span>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" routerLink="/admin/verify">
                <i class="menu-icon typcn typcn-tick"></i>
                <span class="menu-title">Verify Account</span>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" routerLink="/admin/revalidation/user/update">
                <i class="menu-icon typcn typcn-tick"></i>
                <span class="menu-title">Re-validation</span>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" routerLink="/admin/export">
                <i class="menu-icon typcn typcn-business-card"></i>
                <span class="menu-title">Export Card</span>
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link" routerLink="/admin/profile">
                <i class="menu-icon typcn typcn-user"></i>
                <span class="menu-title">Profile</span>
              </a>
            </li>
          </ul>
        </nav>
        <!-- partial -->
        <div class="main-panel">
          <div class="content-wrapper">
            <!-- Page Title Header Starts-->
            <div class="modal fade" id="{{ user?.fingerId }}" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" *ngFor = "let user of users">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">ID Card</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <app-card [data]="user"></app-card>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
            </div>
            <div class="row page-title-header">
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                      <div class="card-body">
                        <a class="btn btn-primary" routerLink="/admin/dashboard">Back</a>
                        <h4 class="card-title">Registerd Users by Agent</h4>
                        <div class="row">
                            <button class="btn btn-secondary" (click)="exporter.exportTable('csv')">Csv</button> &nbsp;
                            <button class="btn btn-secondary" (click)="exporter.exportTable('json')">Json</button> &nbsp;
                            <button class="btn btn-secondary" (click)="exporter.exportTable('txt')">Txt</button>
                          </div>
                          
                          <mat-form-field>
                            <mat-label>Filter</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Eg. Name" #input>
                          </mat-form-field>
                          <div class="example-container mat-elevation-z8">
  
                            <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort matSortStart="desc">
  
                              <!-- Name Column -->
                              <ng-container matColumnDef="firstname">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.firstname}} {{row.middlename}} {{row.lastname}} </mat-cell>
                              </ng-container>
  
                              <!-- UniqueID Column -->
                              <ng-container matColumnDef="uniqueId">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Unique ID </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.uniqueId | uppercase}} </mat-cell>
                              </ng-container>
  
                              <!-- Email Column -->
                              <ng-container matColumnDef="email">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.email}} </mat-cell>
                              </ng-container>
  
                              <!-- Gender Column -->
                              <ng-container matColumnDef="gender">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Gender </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.gender}} </mat-cell>
                              </ng-container>
  
                              <!-- DOB Column -->
                              <ng-container matColumnDef="dob">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> D.O.B </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.dob | date}} </mat-cell>
                              </ng-container>
  
                              <!-- Phone no Column -->
                              <ng-container matColumnDef="Phone No">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Phone No </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.phone_no}} </mat-cell>
                              </ng-container>
  
                               <!-- Branch Column -->
                               <ng-container matColumnDef="branch">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Branch </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.branch}} </mat-cell>
                              </ng-container>
  
                              <!-- Zone Column -->
                              <ng-container matColumnDef="zone">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Zone 
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.zone}} </mat-cell>
                              </ng-container>
  
                              <!-- State Column -->
                              <ng-container matColumnDef="state">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> State </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.state}} </mat-cell>
                              </ng-container>
  
                              <!-- Unit Column -->
                              <ng-container matColumnDef="unit">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Unit
                                </mat-header-cell>
                                <mat-cell *matCellDef="let row"> {{row.unit}} </mat-cell>
                              </ng-container>
  
                              <!-- Action Column -->
                              <ng-container matColumnDef="Action">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Action </mat-header-cell>
                                <mat-cell *matCellDef="let row"> 
                                  <button class="btn btn-secondary" routerLink="/admin/agent/{{row._id}}/users">View
                                  </button> &nbsp;
                                    <button class="btn badge {{row.approve? 'badge-success':'badge-danger'}}">{{row.approve? 'Approved' : 'Pending'}}</button>
                                  </mat-cell>
                              </ng-container>
  
                              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                              <mat-row *matRowDef="let row; columns: displayedColumns;">
                              </mat-row>
                            </mat-table>
  
                            <mat-paginator [pageSizeOptions]="[20, 50, 100, 500]"></mat-paginator>
                          </div>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
          <!-- content-wrapper ends -->
          <!-- partial:partials/_footer.html -->
          <footer class="footer">
            <div class="container-fluid clearfix">
              <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © 2020. All rights reserved.</span>
              <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i class="mdi mdi-heart text-danger"></i>
              </span>
            </div>
          </footer>
          <!-- partial -->
        </div>
        <!-- main-panel ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>