<div class="credit-card-wrap">
  <div class="mk-icon-world-map"></div>
  <div class="credit-card-inner">
    <header class="header">
      <div class="credit-logo">
        <div class="shape"><span class="txt">NP</span></div> <span class="text">NUPENG</span>
      </div>
    </header>
    <div class="mk-icon-sim"><img [src]="data?.image" style="width: 80px;
    height: 80px;
    border-radius: 50%;"></div>
    <div class="credit-font credit-card-number">{{ data?.firstname }} {{data?.middlename?.charAt(0)}}. {{data?.lastname}}</div>
    <footer class="foot">
      <div class="clearfix">
        <!-- <div class="pull-left">
          <div class="credit-card-date"><span class="title">Gender</span><span class="credit-font">{{ data?.gender }}</span></div>
        </div> -->
        <div class="pull-left">
          <div class="credit-font credit-author">{{ data?.uniqueId | uppercase }}</div>
        </div>
        <div class="pull-right credit-author credit-card-date">{{ data?.gender }}</div>
      </div>
    </footer>
  </div>
</div>